// Common default props and types for charts
export const defaultChartMargin = { top: 5, right: 5, bottom: 5, left: 5 };

export const defaultAxisProps = {
  stroke: '#666',
  tick: { fill: '#666' },
  tickLine: { stroke: '#666' },
  scale: 'auto',
  allowDecimals: true,
  allowDataOverflow: false,
  allowDuplicatedCategory: true,
  domain: ['auto', 'auto'],
  padding: { left: 0, right: 0 },
} as const;

export const defaultTooltipStyle = {
  borderRadius: '8px',
  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
} as const;