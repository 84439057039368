import { memo } from 'react';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription } from "@/components/ui/dialog";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { ThemeColors, Opportunity } from '@/types/profit';
import { Lightbulb, Target, BarChart, Shield, TrendingUp, BookOpen } from 'lucide-react';

interface ProfitStepsModalProps {
  opportunity: Opportunity;
  open: boolean;
  onClose: () => void;
  customColors: ThemeColors;
}

interface StepCardProps {
  title: string;
  description: string;
  icon: React.ElementType;
  customColors: ThemeColors;
}

const StepCard = memo(function StepCard({ title, description, icon: Icon, customColors }: StepCardProps) {
  return (
    <Card style={{ backgroundColor: customColors.muted }}>
      <CardHeader className="p-4">
        <CardTitle className="flex items-center gap-2 text-base">
          <Icon className="h-5 w-5" style={{ color: customColors.primary }} />
          <span style={{ color: customColors.text }}>{title}</span>
        </CardTitle>
      </CardHeader>
      <CardContent className="p-4 pt-0">
        <p className="text-sm" style={{ color: customColors.secondary }}>
          {description}
        </p>
      </CardContent>
    </Card>
  );
});

const DataSourceInfo = memo(function DataSourceInfo({ customColors }: { customColors: ThemeColors }) {
  return (
    <div className="mt-6 border-t" style={{ borderColor: customColors.muted }}>
      <div className="pt-6">
        <h4 className="mb-2 font-semibold" style={{ color: customColors.text }}>Data Sources & Assessment Methodology</h4>
        <div className="space-y-2">
          <p className="text-sm" style={{ color: customColors.secondary }}>
            Our assessments are based on data from:
          </p>
          <ul className="list-disc pl-5 space-y-1">
            <li className="text-sm" style={{ color: customColors.secondary }}>
              Market analysis from Bloomberg, Reuters, and Financial Times
            </li>
            <li className="text-sm" style={{ color: customColors.secondary }}>
              Industry reports from McKinsey, Deloitte, and Gartner
            </li>
            <li className="text-sm" style={{ color: customColors.secondary }}>
              Economic indicators from World Bank and IMF
            </li>
            <li className="text-sm" style={{ color: customColors.secondary }}>
              Technical analysis using proprietary AI algorithms
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
});

export const ProfitStepsModal = memo(function ProfitStepsModal({
  opportunity,
  open,
  onClose,
  customColors
}: ProfitStepsModalProps) {
  const steps = [
    {
      title: "Market Research",
      description: `Analyze ${opportunity.category.toLowerCase()} market trends, focusing on ${opportunity.profitMetrics.marketSize} total addressable market. Monitor key indicators and competitive landscape.`,
      icon: Lightbulb
    },
    {
      title: "Risk Assessment",
      description: `Evaluate ${opportunity.riskLevel.toLowerCase()} risk factors including ${opportunity.profitMetrics.riskFactors.join(", ")}. Set up risk mitigation strategies.`,
      icon: Shield
    },
    {
      title: "Investment Planning",
      description: `Plan for ${opportunity.profitMetrics.initialInvestment} initial investment with ${opportunity.profitMetrics.projectedAnnualReturn} projected return over ${opportunity.timeline}.`,
      icon: Target
    },
    {
      title: "Performance Tracking",
      description: `Monitor ROI targeting ${opportunity.roi} with ${opportunity.profitMetrics.breakevenPeriod} breakeven period. Set up tracking metrics and KPIs.`,
      icon: BarChart
    },
    {
      title: "Growth Strategy",
      description: `Implement scaling plan targeting ${opportunity.growth} growth rate. Focus on market expansion and optimization.`,
      icon: TrendingUp
    },
    {
      title: "Continuous Learning",
      description: "Stay updated with industry news, regulatory changes, and market developments. Adjust strategy based on new information.",
      icon: BookOpen
    }
  ];

  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent 
        className="max-w-3xl"
        style={{ backgroundColor: customColors.cardBackground }}
      >
        <DialogHeader>
          <DialogTitle style={{ color: customColors.text }}>
            Profit Strategy: {opportunity.title}
          </DialogTitle>
          <DialogDescription style={{ color: customColors.secondary }}>
            Follow these steps to maximize your investment potential
          </DialogDescription>
        </DialogHeader>

        <ScrollArea className="h-[500px] pr-4">
          <div className="grid gap-4">
            {steps.map((step, index) => (
              <StepCard
                key={index}
                title={`${index + 1}. ${step.title}`}
                description={step.description}
                icon={step.icon}
                customColors={customColors}
              />
            ))}
          </div>

          <DataSourceInfo customColors={customColors} />
        </ScrollArea>

        <div className="mt-4">
          <Button 
            onClick={onClose}
            className="w-full"
            style={{ 
              backgroundColor: customColors.primary,
              color: customColors.background
            }}
          >
            Close
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
});