import { useEffect, useState, useCallback } from 'react';
import { supabase } from '@/lib/supabase';
import { Opportunity, RiskLevel, Category } from '@/types/profit';
import { useToast } from '@/hooks/use-toast';
import { profitData } from '@/data/profitData';
import { normalizeRiskLevel } from '@/utils/profitUtils';

interface DatabaseOpportunity {
  id: number;
  title: string;
  volume: string;
  growth: string;
  roi: string;
  risk_levels: { name: string } | null;
  timeline: string;
  categories: { name: string } | null;
  description: string;
  long_description: string;
  initial_investment: string;
  projected_annual_return: string;
  breakeven_period: string;
  market_size: string;
  potential_profit: string;
  risk_factors: string[];
  success_rate: string;
  trend: Array<{ x: number; y: number; roi?: number }>;
  status: string;
  is_premium: boolean;
  icon: string;
}

export function useOpportunities() {
  const [opportunities, setOpportunities] = useState<Opportunity[]>(profitData);
  const [loading, setLoading] = useState(true);
  const { toast } = useToast();

  const fetchOpportunities = useCallback(async () => {
    try {
      // First check if we have a connection to Supabase
      const { data: connectionTest, error: connectionError } = await supabase
        .from('opportunities')
        .select('count')
        .single();

      if (connectionError) {
        console.info('Using local data while database connection is established');
        setOpportunities(profitData);
        return;
      }

      const { data, error } = await supabase
        .from('opportunities')
        .select(`
          *,
          categories:categories!inner(name),
          risk_levels:risk_levels!inner(name)
        `)
        .order('created_at', { ascending: false });

      if (error) throw error;

      if (data) {
        const formattedData: Opportunity[] = (data as unknown as DatabaseOpportunity[]).map(item => ({
          id: item.id,
          title: item.title,
          volume: item.volume,
          growth: item.growth,
          roi: item.roi,
          riskLevel: normalizeRiskLevel(item.risk_levels?.name || 'MEDIUM'),
          timeline: item.timeline,
          category: (item.categories?.name || 'TECHNOLOGY') as Category,
          description: item.description,
          longDescription: item.long_description,
          profitMetrics: {
            initialInvestment: item.initial_investment,
            projectedAnnualReturn: item.projected_annual_return,
            breakevenPeriod: item.breakeven_period,
            marketSize: item.market_size,
            potentialProfit: item.potential_profit,
            riskFactors: item.risk_factors,
            successRate: item.success_rate
          },
          trend: item.trend.map(t => ({
            x: t.x,
            y: t.y,
            roi: t.roi
          })),
          status: item.status,
          isPremium: item.is_premium,
          icon: item.icon
        }));

        setOpportunities(formattedData);
      }
    } catch (error) {
      console.error('Error fetching opportunities:', error);
      setOpportunities(profitData);
      
      toast({
        title: "Using Offline Data",
        description: "Unable to connect to database. Using local data instead.",
        variant: "default",
      });
    } finally {
      setLoading(false);
    }
  }, [toast]);

  useEffect(() => {
    fetchOpportunities();
  }, [fetchOpportunities]);

  return { 
    opportunities, 
    loading,
    refetch: fetchOpportunities
  };
}