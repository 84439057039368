import { ElementType } from 'react';
import { 
  TrendingUp, 
  DollarSign, 
  Leaf, 
  Fingerprint, 
  Wheat, 
  Briefcase, 
  GraduationCap, 
  Stethoscope, 
  Cpu 
} from 'lucide-react';

const icons: { [key: string]: ElementType } = { 
  TrendingUp, 
  DollarSign, 
  Leaf, 
  Fingerprint, 
  Wheat, 
  Briefcase, 
  GraduationCap, 
  Stethoscope, 
  Cpu
};

export function getIconComponent(iconName: string): ElementType {
  return icons[iconName] || DollarSign;
}