import { createContext, useContext, useState, useCallback, useMemo, ReactNode } from 'react';
import { themes } from '@/config/themes';
import type { Theme, ThemeColors, ThemeContextType } from '@/types/profit';

const ThemeContext = createContext<ThemeContextType | null>(null);

export function ThemeProvider({ children }: { children: ReactNode }) {
  const [theme, setTheme] = useState<Theme>('light');

  const toggleTheme = useCallback(() => {
    setTheme((prev) => prev === 'light' ? 'dark' : 'light');
  }, []);

  const colors = useMemo(() => themes[theme], [theme]);

  const value = useMemo<ThemeContextType>(() => ({
    theme,
    colors,
    toggleTheme,
  }), [theme, colors, toggleTheme]);

  return (
    <ThemeContext.Provider value={value}>
      {children}
    </ThemeContext.Provider>
  );
}

export function useTheme() {
  const context = useContext(ThemeContext);
  if (!context) {
    throw new Error('useTheme must be used within a ThemeProvider');
  }
  return context;
}