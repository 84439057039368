import { memo } from 'react';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription } from "@/components/ui/dialog";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { Sparkles, History, HelpCircle } from 'lucide-react';
import { ThemeColors } from '@/types/profit';
import { useAuth } from '@/providers/AuthProvider';
import { AIInsight } from '@/hooks/useAIInsights';
import { InsightHistory } from './AIInsights/InsightHistory';
import { Tutorial } from './AIInsights/Tutorial';
import { InsightContent } from './AIInsights/InsightContent';

interface AIInsightsModalProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  insight: string | null;
  insights: AIInsight[];
  loading: boolean;
  onGenerateInsight: () => void;
  customColors: ThemeColors;
}

export const AIInsightsModal = memo(function AIInsightsModal({
  open,
  onOpenChange,
  insight,
  insights,
  loading,
  onGenerateInsight,
  customColors
}: AIInsightsModalProps) {
  const { isPro } = useAuth();

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent 
        className="max-w-3xl"
        style={{ backgroundColor: customColors.cardBackground }}
      >
        <DialogHeader>
          <DialogTitle className="flex items-center gap-2" style={{ color: customColors.text }}>
            <Sparkles className="h-5 w-5" style={{ color: customColors.primary }} />
            AI Investment Insights
          </DialogTitle>
          <DialogDescription style={{ color: customColors.secondary }}>
            Get personalized analysis and recommendations for your investment portfolio
          </DialogDescription>
        </DialogHeader>

        <Tabs defaultValue="insights">
          <TabsList>
            <TabsTrigger value="insights" className="gap-2">
              <Sparkles className="h-4 w-4" />
              Insights
            </TabsTrigger>
            <TabsTrigger value="history" className="gap-2">
              <History className="h-4 w-4" />
              History
            </TabsTrigger>
            <TabsTrigger value="help" className="gap-2">
              <HelpCircle className="h-4 w-4" />
              Help
            </TabsTrigger>
          </TabsList>

          <TabsContent value="insights">
            <div className="mt-4">
              <InsightContent
                isPro={isPro}
                loading={loading}
                insight={insight}
                onUpgrade={() => onOpenChange(false)}
                onGenerate={onGenerateInsight}
                customColors={customColors}
              />
            </div>
          </TabsContent>

          <TabsContent value="history">
            <ScrollArea className="h-[600px] pr-4">
              <InsightHistory insights={insights} customColors={customColors} />
            </ScrollArea>
          </TabsContent>

          <TabsContent value="help">
            <ScrollArea className="h-[600px] pr-4">
              <Tutorial customColors={customColors} />
            </ScrollArea>
          </TabsContent>
        </Tabs>
      </DialogContent>
    </Dialog>
  );
});