import { memo } from 'react';
import { Button } from "@/components/ui/button";
import { ScrollArea } from "@/components/ui/scroll-area";
import { ThemeColors } from '@/types/profit';
import { Loader2, Sparkles, Lock } from 'lucide-react';
import ReactMarkdown from 'react-markdown';

interface InsightContentProps {
  isPro: boolean;
  loading: boolean;
  insight: string | null;
  onUpgrade: () => void;
  onGenerate: () => void;
  customColors: ThemeColors;
}

export const InsightContent = memo(function InsightContent({
  isPro,
  loading,
  insight,
  onUpgrade,
  onGenerate,
  customColors
}: InsightContentProps) {
  if (!isPro) {
    return (
      <div className="text-center py-8">
        <Lock className="h-12 w-12 mx-auto mb-4" style={{ color: customColors.primary }} />
        <h3 className="text-lg font-semibold mb-2" style={{ color: customColors.text }}>
          Upgrade to Pro
        </h3>
        <p className="mb-6 max-w-md mx-auto" style={{ color: customColors.secondary }}>
          Get access to AI-powered investment insights and personalized recommendations to maximize your returns
        </p>
        <Button
          onClick={onUpgrade}
          className="gap-2"
          style={{ 
            backgroundColor: customColors.primary,
            color: customColors.background
          }}
        >
          Upgrade Now
        </Button>
      </div>
    );
  }

  if (loading) {
    return (
      <div className="flex flex-col items-center justify-center py-8">
        <Loader2 className="h-8 w-8 animate-spin mb-4" style={{ color: customColors.primary }} />
        <p style={{ color: customColors.text }}>Analyzing your portfolio...</p>
      </div>
    );
  }

  if (insight) {
    return (
      <ScrollArea className="h-[500px] pr-4">
        <div 
          className="prose prose-neutral dark:prose-invert max-w-none"
          style={{ color: customColors.text }}
        >
          <ReactMarkdown>{insight}</ReactMarkdown>
        </div>
      </ScrollArea>
    );
  }

  return (
    <div className="text-center py-8">
      <Sparkles className="h-12 w-12 mx-auto mb-4" style={{ color: customColors.primary }} />
      <h3 className="text-lg font-semibold mb-2" style={{ color: customColors.text }}>
        Get AI-Powered Investment Insights
      </h3>
      <p className="mb-6" style={{ color: customColors.secondary }}>
        Let our AI analyze your tracked opportunities and provide personalized recommendations
      </p>
      <Button
        onClick={onGenerate}
        className="gap-2"
        style={{ 
          backgroundColor: customColors.primary,
          color: customColors.background
        }}
      >
        <Sparkles className="h-4 w-4" />
        Generate Insights
      </Button>
    </div>
  );
});