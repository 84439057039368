import { memo } from 'react';
import { Card, CardContent, CardHeader, CardTitle, CardDescription } from "@/components/ui/card";
import { ResponsiveContainer, LineChart, CartesianGrid, Tooltip, Line } from 'recharts';
import { ThemeColors } from '@/types/profit';
import { TrendData } from '@/types/profit';
import { XAxis, YAxis } from '../charts/ChartComponents';

interface DailyForecastProps {
  chartData: TrendData[];
  customColors: ThemeColors;
}

export const DailyForecast = memo(function DailyForecast({
  chartData,
  customColors
}: DailyForecastProps) {
  const axisProps = {
    stroke: customColors.secondary,
    tick: { fill: customColors.secondary },
    tickLine: { stroke: customColors.secondary }
  };

  return (
    <Card className="mb-6" style={{ backgroundColor: customColors.cardBackground }}>
      <CardHeader>
        <CardTitle style={{ color: customColors.text }}>Daily Profit Forecast</CardTitle>
        <CardDescription style={{ color: customColors.secondary }}>
          Based on your tracked ideas and market trends
        </CardDescription>
      </CardHeader>
      <CardContent>
        <div className="h-[200px]">
          <ResponsiveContainer width="100%" height="100%">
            <LineChart data={chartData}>
              <CartesianGrid strokeDasharray="3 3" stroke={customColors.muted} />
              <XAxis dataKey="x" {...axisProps} />
              <YAxis {...axisProps} />
              <Tooltip 
                contentStyle={{ 
                  backgroundColor: customColors.cardBackground, 
                  borderColor: customColors.muted,
                  borderRadius: '8px',
                  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)'
                }}
                labelStyle={{ color: customColors.text }}
                formatter={(value: number) => [`${value.toFixed(2)}`, 'Value']}
                labelFormatter={(label) => `Day ${label + 1}`}
              />
              <Line 
                type="monotone" 
                dataKey="y" 
                stroke={customColors.accent} 
                strokeWidth={2} 
                dot={false}
                isAnimationActive={false} 
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
        <div className="mt-4 flex justify-between items-center">
          <span style={{ color: customColors.text }}>Projected Profit Today:</span>
          <span className="text-2xl font-bold" style={{ color: customColors.accent }}>
            ${chartData[chartData.length - 1].y.toFixed(2)}
          </span>
        </div>
      </CardContent>
    </Card>
  );
});