import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { useAuth } from "@/providers/AuthProvider";
import { ThemeColors } from "@/types/profit";
import { User, Settings, LogOut, Star } from "lucide-react";
import { User as SupabaseUser } from '@supabase/supabase-js';

interface UserMenuProps {
  customColors: ThemeColors;
  user: SupabaseUser;
  isPro: boolean;
  onUpgradeClick: () => void;
}

export function UserMenu({ customColors, user, isPro, onUpgradeClick }: UserMenuProps) {
  const { signOut } = useAuth();

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button
          variant="ghost"
          style={{ 
            color: customColors.text,
            backgroundColor: `${customColors.primary}10`
          }}
        >
          <User className="mr-2 h-4 w-4" />
          <span className="max-w-[150px] truncate">{user.email}</span>
          {isPro && (
            <span
              className="ml-2 rounded-full px-2 py-0.5 text-xs"
              style={{ backgroundColor: customColors.accent, color: customColors.background }}
            >
              PRO
            </span>
          )}
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent 
        align="end"
        style={{ 
          backgroundColor: customColors.cardBackground,
          borderColor: customColors.muted
        }}
      >
        <DropdownMenuLabel style={{ color: customColors.text }}>
          My Account
        </DropdownMenuLabel>
        <DropdownMenuSeparator style={{ backgroundColor: customColors.muted }} />
        <DropdownMenuItem
          style={{ color: customColors.text }}
          className="cursor-pointer"
          onClick={() => {}}
        >
          <Settings className="mr-2 h-4 w-4" />
          Settings
        </DropdownMenuItem>
        {!isPro && (
          <DropdownMenuItem
            style={{ color: customColors.warning }}
            className="cursor-pointer"
            onClick={onUpgradeClick}
          >
            <Star className="mr-2 h-4 w-4" />
            Upgrade to Pro
          </DropdownMenuItem>
        )}
        <DropdownMenuSeparator style={{ backgroundColor: customColors.muted }} />
        <DropdownMenuItem
          style={{ color: customColors.danger }}
          className="cursor-pointer"
          onClick={() => signOut()}
        >
          <LogOut className="mr-2 h-4 w-4" />
          Sign Out
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}