import { memo, useState } from 'react';
import { Card, CardContent, CardHeader, CardTitle, CardDescription } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { ThemeColors } from '@/types/profit';
import { useOpportunities } from '@/hooks/useOpportunities';
import { useTrackedOpportunities } from '@/hooks/useTrackedOpportunities';
import { useAIInsights } from '@/hooks/useAIInsights';
import { OpportunityCard } from './OpportunityCard';
import { OpportunityDetails } from './OpportunityDetails';
import { AIInsightsModal } from './AIInsightsModal';
import { TrackedAnalytics } from './TrackedAnalytics';
import { useAuth } from '@/providers/AuthProvider';
import { BookMarked, TrendingUp, Sparkles } from 'lucide-react';

interface TrackedOpportunitiesViewProps {
  customColors: ThemeColors;
}

export const TrackedOpportunitiesView = memo(function TrackedOpportunitiesView({
  customColors
}: TrackedOpportunitiesViewProps) {
  const { opportunities } = useOpportunities();
  const { trackedIds, toggleTrackedOpportunity } = useTrackedOpportunities();
  const { generateInsight, loading: insightLoading } = useAIInsights();
  const { isPro } = useAuth();
  
  const [selectedView, setSelectedView] = useState<'grid' | 'analytics'>('grid');
  const [selectedOpportunity, setSelectedOpportunity] = useState<number | null>(null);
  const [showProfitSteps, setShowProfitSteps] = useState(false);
  const [showAIInsights, setShowAIInsights] = useState(false);
  const [currentInsight, setCurrentInsight] = useState<string | null>(null);

  const trackedOpportunities = opportunities.filter(opp => trackedIds.includes(opp.id));
  const selectedOpportunityData = opportunities.find(opp => opp.id === selectedOpportunity);

  const handleGenerateInsight = async () => {
    const insight = await generateInsight(trackedOpportunities);
    if (insight) {
      setCurrentInsight(insight);
      setShowAIInsights(true);
    }
  };

  return (
    <>
      <Card style={{ backgroundColor: customColors.cardBackground }}>
        <CardHeader>
          <div className="flex items-center justify-between">
            <div>
              <CardTitle className="text-xl" style={{ color: customColors.text }}>
                Tracked Opportunities
              </CardTitle>
              <CardDescription style={{ color: customColors.secondary }}>
                Monitor and analyze your tracked investment opportunities
              </CardDescription>
            </div>
            <div className="flex gap-2">
              <Button
                variant="outline"
                size="sm"
                onClick={handleGenerateInsight}
                disabled={trackedOpportunities.length === 0}
                className="transition-colors hover:bg-primary/20"
                style={{ 
                  backgroundColor: `${customColors.primary}10`,
                  color: customColors.text,
                  borderColor: customColors.muted
                }}
              >
                <Sparkles className="h-4 w-4 mr-2" />
                AI Insights
              </Button>
              <Button
                variant="outline"
                size="sm"
                onClick={() => setSelectedView('grid')}
                className="transition-colors hover:bg-primary/20"
                style={{ 
                  backgroundColor: selectedView === 'grid' ? `${customColors.primary}20` : 'transparent',
                  color: customColors.text,
                  borderColor: customColors.muted
                }}
              >
                <BookMarked className="h-4 w-4 mr-2" />
                Grid
              </Button>
              <Button
                variant="outline"
                size="sm"
                onClick={() => setSelectedView('analytics')}
                className="transition-colors hover:bg-primary/20"
                style={{ 
                  backgroundColor: selectedView === 'analytics' ? `${customColors.primary}20` : 'transparent',
                  color: customColors.text,
                  borderColor: customColors.muted
                }}
              >
                <TrendingUp className="h-4 w-4 mr-2" />
                Analytics
              </Button>
            </div>
          </div>
        </CardHeader>
        <CardContent>
          {trackedOpportunities.length === 0 ? (
            <div className="text-center py-12">
              <BookMarked className="h-12 w-12 mx-auto mb-4" style={{ color: customColors.secondary }} />
              <h3 className="text-lg font-semibold mb-2" style={{ color: customColors.text }}>
                No Tracked Opportunities
              </h3>
              <p style={{ color: customColors.secondary }}>
                Start tracking opportunities to see them here
              </p>
            </div>
          ) : selectedView === 'grid' ? (
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
              {trackedOpportunities.map((opportunity) => (
                <OpportunityCard
                  key={opportunity.id}
                  opportunity={opportunity}
                  customColors={customColors}
                  isTracked={true}
                  onTrackToggle={() => toggleTrackedOpportunity(opportunity.id)}
                  onViewDetails={() => setSelectedOpportunity(opportunity.id)}
                  isPremiumUser={isPro}
                />
              ))}
            </div>
          ) : (
            <TrackedAnalytics 
              opportunities={trackedOpportunities}
              customColors={customColors}
            />
          )}
        </CardContent>
      </Card>

      {selectedOpportunityData && (
        <OpportunityDetails
          opportunity={selectedOpportunityData}
          customColors={customColors}
          isPremiumUser={isPro}
          showProfitSteps={showProfitSteps}
          onClose={() => {
            setSelectedOpportunity(null);
            setShowProfitSteps(false);
          }}
          onShowProfitSteps={() => setShowProfitSteps(true)}
        />
      )}

      <AIInsightsModal
              open={showAIInsights}
              onOpenChange={setShowAIInsights}
              insight={currentInsight}
              loading={insightLoading}
              onGenerateInsight={handleGenerateInsight}
              customColors={customColors} insights={[]}      />
    </>
  );
});