import { useState } from 'react';
import { Opportunity, ThemeColors } from '@/types/profit';
import { OpportunityCard } from './OpportunityCard';
import { OpportunityDetails } from './OpportunityDetails';
import { Button } from "@/components/ui/button";
import { ChevronLeft, ChevronRight, SearchX } from 'lucide-react';

interface OpportunityGridProps {
  opportunities: Opportunity[];
  customColors: ThemeColors;
  isPremiumUser: boolean;
  trackedIds: number[];
  onTrackToggle: (id: number) => void;
}

const ITEMS_PER_PAGE = 12;

export function OpportunityGrid({
  opportunities,
  customColors,
  isPremiumUser,
  trackedIds,
  onTrackToggle
}: OpportunityGridProps) {
  const [selectedOpportunity, setSelectedOpportunity] = useState<Opportunity | null>(null);
  const [showProfitSteps, setShowProfitSteps] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const totalPages = Math.ceil(opportunities.length / ITEMS_PER_PAGE);
  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  const endIndex = startIndex + ITEMS_PER_PAGE;
  const currentOpportunities = opportunities.slice(startIndex, endIndex);

  return (
    <div className="container mx-auto px-4 max-w-[2000px]">
      {opportunities.length === 0 ? (
        <div 
          className="flex flex-col items-center justify-center bg-opacity-50 rounded-lg p-8"
          style={{ 
            backgroundColor: `${customColors.cardBackground}50`,
            border: `1px dashed ${customColors.muted}`,
            minHeight: '600px'
          }}
        >
          <SearchX 
            className="h-16 w-16 mb-4" 
            style={{ color: customColors.secondary }} 
          />
          <h3 
            className="text-xl font-semibold mb-2"
            style={{ color: customColors.text }}
          >
            No opportunities found
          </h3>
          <p 
            className="text-sm max-w-md text-center"
            style={{ color: customColors.secondary }}
          >
            Try adjusting your filters or search criteria to find more investment opportunities.
          </p>
        </div>
      ) : (
        <div className="min-h-[600px] flex flex-col">
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-6 flex-grow">
            {currentOpportunities.map((opportunity) => (
              <OpportunityCard
                key={opportunity.id}
                opportunity={opportunity}
                customColors={customColors}
                isTracked={trackedIds.includes(opportunity.id)}
                onTrackToggle={() => onTrackToggle(opportunity.id)}
                onViewDetails={() => setSelectedOpportunity(opportunity)}
                isPremiumUser={isPremiumUser}
              />
            ))}
          </div>

          {totalPages > 1 && (
            <div className="mt-8 flex justify-center items-center gap-4">
              <Button
                variant="outline"
                size="icon"
                onClick={() => setCurrentPage(prev => Math.max(1, prev - 1))}
                disabled={currentPage === 1}
                style={{ 
                  borderColor: customColors.muted,
                  color: customColors.secondary,
                  backgroundColor: `${customColors.primary}10`
                }}
              >
                <ChevronLeft className="h-4 w-4" />
              </Button>
              <span style={{ color: customColors.text }}>
                Page {currentPage} of {totalPages}
              </span>
              <Button
                variant="outline"
                size="icon"
                onClick={() => setCurrentPage(prev => Math.min(totalPages, prev + 1))}
                disabled={currentPage === totalPages}
                style={{ 
                  borderColor: customColors.muted,
                  color: customColors.secondary,
                  backgroundColor: `${customColors.primary}10`
                }}
              >
                <ChevronRight className="h-4 w-4" />
              </Button>
            </div>
          )}
        </div>
      )}

      {selectedOpportunity && (
        <OpportunityDetails
          opportunity={selectedOpportunity}
          customColors={customColors}
          isPremiumUser={isPremiumUser}
          showProfitSteps={showProfitSteps}
          onClose={() => {
            setSelectedOpportunity(null);
            setShowProfitSteps(false);
          }}
          onShowProfitSteps={() => setShowProfitSteps(true)}
        />
      )}
    </div>
  );
}