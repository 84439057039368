import { memo } from 'react';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription } from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Card, CardHeader, CardTitle } from "@/components/ui/card";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { ScrollArea } from "@/components/ui/scroll-area";
import { ResponsiveContainer, LineChart, CartesianGrid, Tooltip, Line, BarChart, Bar } from 'recharts';
import { Lock } from 'lucide-react';
import { ThemeColors, Opportunity } from '@/types/profit';
import { getIconComponent } from '@/utils/iconUtils';
import { XAxis, YAxis } from '../charts/ChartComponents';
import { formatCurrency } from '@/utils/formatUtils';
import { ProfitStepsModal } from './ProfitStepsModal';

interface OpportunityDetailsProps {
  opportunity: Opportunity;
  customColors: ThemeColors;
  isPremiumUser: boolean;
  showProfitSteps: boolean;
  onClose: () => void;
  onShowProfitSteps: () => void;
}

const MetricCard = memo(function MetricCard({ 
  title, 
  value, 
  customColors 
}: { 
  title: string; 
  value: string | number; 
  customColors: ThemeColors; 
}) {
  return (
    <Card style={{ backgroundColor: customColors.muted }}>
      <CardHeader className="p-4">
        <CardTitle className="text-sm" style={{ color: customColors.secondary }}>{title}</CardTitle>
        <div className="text-2xl font-bold" style={{ color: customColors.primary }}>
          {value}
        </div>
      </CardHeader>
    </Card>
  );
});

export const OpportunityDetails = memo(function OpportunityDetails({
  opportunity,
  customColors,
  isPremiumUser,
  showProfitSteps,
  onClose,
  onShowProfitSteps
}: OpportunityDetailsProps) {
  const IconComponent = getIconComponent(opportunity.icon);

  return (
    <>
      <Dialog open={true} onOpenChange={onClose}>
        <DialogContent 
          className="max-w-4xl"
          style={{ 
            backgroundColor: customColors.cardBackground,
            color: customColors.text
          }}
        >
          <DialogHeader>
            <DialogTitle className="flex items-center gap-2 text-2xl font-bold">
              <div 
                className="rounded-lg p-2"
                style={{ 
                  background: `linear-gradient(135deg, ${customColors.primary}20, ${customColors.primary}40)`,
                  boxShadow: `0 0 20px ${customColors.primary}10`
                }}
              >
                <IconComponent className="h-6 w-6" style={{ color: customColors.primary }} />
              </div>
              {opportunity.title}
            </DialogTitle>
            <DialogDescription style={{ color: customColors.secondary }}>
              {opportunity.category} | {opportunity.timeline}
            </DialogDescription>
          </DialogHeader>

          <Tabs defaultValue="overview">
            <TabsList 
              className="bg-transparent border-b"
              style={{ borderColor: customColors.muted }}
            >
              {['overview', 'metrics', 'analysis'].map((tab) => (
                <TabsTrigger 
                  key={tab} 
                  value={tab}
                  className="data-[state=active]:border-b-2 transition-colors"
                  style={{
                    color: customColors.text,
                    borderColor: customColors.primary,
                    backgroundColor: 'transparent'
                  }}
                >
                  {tab.charAt(0).toUpperCase() + tab.slice(1)}
                </TabsTrigger>
              ))}
            </TabsList>

            <TabsContent value="overview" style={{ color: customColors.text }}>
              <div className="grid gap-4">
                <div className="h-[200px]">
                  <ResponsiveContainer width="100%" height="100%">
                    <LineChart data={opportunity.trend}>
                      <CartesianGrid strokeDasharray="3 3" stroke={customColors.muted} />
                      <XAxis dataKey="x" stroke={customColors.secondary} />
                      <YAxis stroke={customColors.secondary} />
                      <Tooltip 
                        contentStyle={{ backgroundColor: customColors.cardBackground, borderColor: customColors.muted }}
                        labelStyle={{ color: customColors.text }}
                      />
                      <Line 
                        type="monotone" 
                        dataKey="y" 
                        stroke={customColors.primary} 
                        strokeWidth={2}
                        isAnimationActive={false}
                      />
                    </LineChart>
                  </ResponsiveContainer>
                </div>

                <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
                  <MetricCard title="Volume" value={formatCurrency(parseFloat(opportunity.volume))} customColors={customColors} />
                  <MetricCard title="Growth" value={opportunity.growth} customColors={customColors} />
                  <MetricCard title="ROI" value={opportunity.roi} customColors={customColors} />
                  <MetricCard title="Risk Level" value={opportunity.riskLevel} customColors={customColors} />
                </div>

                <div className="relative">
                  <p style={{ color: customColors.text }} className="text-sm leading-relaxed">
                    {opportunity.longDescription}
                  </p>
                  {opportunity.isPremium && !isPremiumUser && (
                    <div 
                      className="absolute inset-0 flex items-center justify-center backdrop-blur-sm" 
                      style={{ backgroundColor: `${customColors.cardBackground}cc` }}
                    >
                      <div className="text-center">
                        <Lock className="mx-auto mb-2 h-8 w-8" style={{ color: customColors.accent }} />
                        <p className="text-lg font-bold" style={{ color: customColors.text }}>Premium Content</p>
                        <p className="mb-4 text-sm" style={{ color: customColors.secondary }}>
                          Upgrade to access detailed analysis
                        </p>
                        <Button 
                          onClick={onClose}
                          style={{ backgroundColor: customColors.primary, color: customColors.background }}
                        >
                          Upgrade Now
                        </Button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </TabsContent>

            <TabsContent value="metrics" style={{ color: customColors.text }}>
              <div className="grid gap-4">
                <div className="grid grid-cols-2 gap-4">
                  <MetricCard 
                    title="Initial Investment" 
                    value={formatCurrency(parseFloat(opportunity.profitMetrics.initialInvestment))} 
                    customColors={customColors} 
                  />
                  <MetricCard 
                    title="Projected Annual Return" 
                    value={opportunity.profitMetrics.projectedAnnualReturn} 
                    customColors={customColors} 
                  />
                </div>

                <div className="grid grid-cols-2 gap-4">
                  <div className="h-[200px]">
                    <ResponsiveContainer width="100%" height="100%">
                      <BarChart data={opportunity.trend.slice(-12)}>
                        <CartesianGrid strokeDasharray="3 3" stroke={customColors.muted} />
                        <XAxis dataKey="x" stroke={customColors.secondary} />
                        <YAxis stroke={customColors.secondary} />
                        <Tooltip 
                          contentStyle={{ backgroundColor: customColors.cardBackground, borderColor: customColors.muted }}
                          labelStyle={{ color: customColors.text }}
                        />
                        <Bar 
                          dataKey="y" 
                          fill={customColors.primary} 
                          radius={[4, 4, 0, 0]} 
                          isAnimationActive={false}
                        />
                      </BarChart>
                    </ResponsiveContainer>
                  </div>
                  <div className="h-[200px]">
                    <ResponsiveContainer width="100%" height="100%">
                      <LineChart data={opportunity.trend}>
                        <CartesianGrid strokeDasharray="3 3" stroke={customColors.muted} />
                        <XAxis dataKey="x" stroke={customColors.secondary} />
                        <YAxis stroke={customColors.secondary} />
                        <Tooltip 
                          contentStyle={{ backgroundColor: customColors.cardBackground, borderColor: customColors.muted }}
                          labelStyle={{ color: customColors.text }}
                        />
                        <Line 
                          type="monotone" 
                          dataKey="roi" 
                          stroke={customColors.accent}
                          strokeWidth={2}
                          isAnimationActive={false}
                        />
                      </LineChart>
                    </ResponsiveContainer>
                  </div>
                </div>
              </div>
            </TabsContent>

            <TabsContent value="analysis" style={{ color: customColors.text }}>
              <ScrollArea className="h-[400px]">
                <div className="grid gap-4">
                  <Card style={{ backgroundColor: customColors.muted }}>
                    <CardHeader className="p-4">
                      <CardTitle className="text-lg" style={{ color: customColors.text }}>
                        Market Analysis
                      </CardTitle>
                      <p className="text-sm" style={{ color: customColors.text }}>
                        Market size: {formatCurrency(parseFloat(opportunity.profitMetrics.marketSize))}
                        <br />
                        Success rate: {opportunity.profitMetrics.successRate}
                      </p>
                    </CardHeader>
                  </Card>

                  <Card style={{ backgroundColor: customColors.muted }}>
                    <CardHeader className="p-4">
                      <CardTitle className="text-lg" style={{ color: customColors.text }}>
                        Risk Factors
                      </CardTitle>
                      <ul className="mt-2 list-disc pl-4">
                        {opportunity.profitMetrics.riskFactors.map((factor, index) => (
                          <li key={index} className="text-sm" style={{ color: customColors.text }}>
                            {factor}
                          </li>
                        ))}
                      </ul>
                    </CardHeader>
                  </Card>
                </div>
              </ScrollArea>
            </TabsContent>
          </Tabs>

          <div className="mt-4 flex justify-between">
            <Button
              onClick={onShowProfitSteps}
              style={{ 
                backgroundColor: customColors.primary,
                color: customColors.background
              }}
              className="transition-colors duration-200 hover:opacity-90"
            >
              View Profit Steps
            </Button>
            <Button
              onClick={onClose}
              variant="outline"
              style={{ 
                backgroundColor: `${customColors.primary}10`,
                color: customColors.text,
                borderColor: customColors.muted
              }}
              className="transition-colors duration-200 hover:opacity-90"
            >
              Close
            </Button>
          </div>
        </DialogContent>
      </Dialog>

      {showProfitSteps && (
        <ProfitStepsModal
          opportunity={opportunity}
          open={showProfitSteps}
          onClose={() => {
            onClose();
            onShowProfitSteps();
          }}
          customColors={customColors}
        />
      )}
    </>
  );
});