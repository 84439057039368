import { Search, X } from 'lucide-react';
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { ThemeColors } from '@/types/profit';
import { timelineOptions, categoryOptions, riskLevelOptions, profitRangeOptions } from '@/config/constants';

interface FiltersProps {
  searchQuery: string;
  selectedTimeline: string;
  selectedCategory: string;
  selectedRiskLevel: string;
  selectedProfitRange: string;
  onSearchChange: (value: string) => void;
  onTimelineChange: (value: string) => void;
  onCategoryChange: (value: string) => void;
  onRiskLevelChange: (value: string) => void;
  onProfitRangeChange: (value: string) => void;
  onResetFilters: () => void;
  customColors: ThemeColors;
}

export function Filters({
  searchQuery,
  selectedTimeline,
  selectedCategory,
  selectedRiskLevel,
  selectedProfitRange,
  onSearchChange,
  onTimelineChange,
  onCategoryChange,
  onRiskLevelChange,
  onProfitRangeChange,
  onResetFilters,
  customColors,
}: FiltersProps) {
  const hasActiveFilters = searchQuery || 
    selectedTimeline !== timelineOptions[0] || 
    selectedCategory !== categoryOptions[0] || 
    selectedRiskLevel !== riskLevelOptions[0] || 
    selectedProfitRange !== profitRangeOptions[0];

  return (
    <div className="mb-8 space-y-4">
      <div className="flex flex-wrap items-center justify-between gap-4">
        <div className="flex-1">
          <div className="relative">
            <Search 
              className="absolute left-3 top-1/2 h-4 w-4 -translate-y-1/2" 
              style={{ color: customColors.secondary }} 
            />
            <Input
              placeholder="Search opportunities..."
              value={searchQuery}
              onChange={(e) => onSearchChange(e.target.value)}
              className="w-full pl-10"
              style={{ 
                backgroundColor: customColors.cardBackground, 
                color: customColors.text, 
                borderColor: customColors.muted 
              }}
            />
          </div>
        </div>
        <div className="flex flex-wrap items-center gap-2">
          <Select value={selectedTimeline} onValueChange={onTimelineChange}>
            <SelectTrigger 
              className="w-[150px]" 
              style={{ backgroundColor: customColors.cardBackground, color: customColors.text }}
            >
              <SelectValue placeholder="Timeline" />
            </SelectTrigger>
            <SelectContent>
              {timelineOptions.map((option) => (
                <SelectItem key={option} value={option}>
                  {option}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>

          <Select value={selectedCategory} onValueChange={onCategoryChange}>
            <SelectTrigger 
              className="w-[180px]" 
              style={{ backgroundColor: customColors.cardBackground, color: customColors.text }}
            >
              <SelectValue placeholder="Category" />
            </SelectTrigger>
            <SelectContent>
              {categoryOptions.map((option) => (
                <SelectItem key={option} value={option}>
                  {option}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>

          <Select value={selectedRiskLevel} onValueChange={onRiskLevelChange}>
            <SelectTrigger 
              className="w-[150px]" 
              style={{ backgroundColor: customColors.cardBackground, color: customColors.text }}
            >
              <SelectValue placeholder="Risk Level" />
            </SelectTrigger>
            <SelectContent>
              {riskLevelOptions.map((option) => (
                <SelectItem key={option} value={option}>
                  {option}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>

          <Select value={selectedProfitRange} onValueChange={onProfitRangeChange}>
            <SelectTrigger 
              className="w-[150px]" 
              style={{ backgroundColor: customColors.cardBackground, color: customColors.text }}
            >
              <SelectValue placeholder="Profit Range" />
            </SelectTrigger>
            <SelectContent>
              {profitRangeOptions.map((option) => (
                <SelectItem key={option} value={option}>
                  {option}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>

          {hasActiveFilters && (
            <Button
              variant="outline"
              size="icon"
              onClick={onResetFilters}
              className="h-10 w-10 hover:bg-opacity-20"
              style={{ 
                backgroundColor: `${customColors.primary}10`,
                color: customColors.text,
                borderColor: customColors.muted
              }}
            >
              <X className="h-4 w-4" style={{ color: customColors.text }} />
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}