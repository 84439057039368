import { useState } from 'react';
import { useAuth } from '@/providers/AuthProvider';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription } from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { ThemeColors } from '@/types/profit';
import { Loader2, Mail, Lock, Github } from 'lucide-react';
import { supabase } from '@/lib/supabase';
import { Separator } from '@/components/ui/separator';

interface AuthModalProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  customColors: ThemeColors;
}

export function AuthModal({ open, onOpenChange, customColors }: AuthModalProps) {
  const { signIn, signUp } = useAuth();
  const [isLogin, setIsLogin] = useState(true);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });

  const handleSocialLogin = async (provider: 'google' | 'github') => {
    try {
      const { error } = await supabase.auth.signInWithOAuth({
        provider,
        options: {
          redirectTo: `${window.location.origin}/auth/callback`
        }
      });

      if (error) throw error;
    } catch (error) {
      console.error('Social login error:', error);
    }
  };

  const handleEmailLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    if (loading) return;
    
    setLoading(true);

    try {
      if (isLogin) {
        await signIn(formData.email, formData.password);
        onOpenChange(false);
      } else {
        await signUp(formData.email, formData.password);
      }
    } catch (error) {
      console.error('Auth error:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent 
        className="sm:max-w-[425px]"
        style={{ backgroundColor: customColors.cardBackground }}
      >
        <DialogHeader>
          <DialogTitle style={{ color: customColors.text }}>
            {isLogin ? 'Welcome Back' : 'Create Account'}
          </DialogTitle>
          <DialogDescription style={{ color: customColors.secondary }}>
            {isLogin 
              ? 'Sign in to access your investment dashboard' 
              : 'Join us to start tracking investment opportunities'}
          </DialogDescription>
        </DialogHeader>

        <div className="grid gap-4 py-4">
          <Button
            type="button"
            variant="outline"
            className="w-full"
            onClick={() => handleSocialLogin('google')}
            style={{ 
              borderColor: customColors.muted,
              color: customColors.text,
              backgroundColor: `${customColors.primary}10`
            }}
          >
            <svg className="mr-2 h-4 w-4" viewBox="0 0 24 24">
              <path
                d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z"
                fill="#4285F4"
              />
              <path
                d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z"
                fill="#34A853"
              />
              <path
                d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z"
                fill="#FBBC05"
              />
              <path
                d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z"
                fill="#EA4335"
              />
            </svg>
            Continue with Google
          </Button>

          <Button
            type="button"
            variant="outline"
            className="w-full"
            onClick={() => handleSocialLogin('github')}
            style={{ 
              borderColor: customColors.muted,
              color: customColors.text,
              backgroundColor: `${customColors.primary}10`
            }}
          >
            <Github className="mr-2 h-4 w-4" />
            Continue with GitHub
          </Button>

          <div className="relative">
            <div className="absolute inset-0 flex items-center">
              <Separator style={{ backgroundColor: customColors.muted }} />
            </div>
            <div className="relative flex justify-center text-xs uppercase">
              <span className="px-2" style={{ 
                backgroundColor: customColors.cardBackground,
                color: customColors.secondary 
              }}>
                Or continue with email
              </span>
            </div>
          </div>

          <form onSubmit={handleEmailLogin} className="space-y-4">
            <div className="space-y-2">
              <Label 
                htmlFor="email"
                style={{ color: customColors.text }}
              >
                Email
              </Label>
              <div className="relative">
                <Mail 
                  className="absolute left-3 top-1/2 h-4 w-4 -translate-y-1/2" 
                  style={{ color: customColors.secondary }}
                />
                <Input
                  id="email"
                  name="email"
                  type="email"
                  required
                  value={formData.email}
                  onChange={handleInputChange}
                  className="pl-10"
                  placeholder="Enter your email"
                  style={{ 
                    backgroundColor: customColors.background,
                    color: customColors.text,
                    borderColor: customColors.muted
                  }}
                  disabled={loading}
                />
              </div>
            </div>
            <div className="space-y-2">
              <Label 
                htmlFor="password"
                style={{ color: customColors.text }}
              >
                Password
              </Label>
              <div className="relative">
                <Lock 
                  className="absolute left-3 top-1/2 h-4 w-4 -translate-y-1/2" 
                  style={{ color: customColors.secondary }}
                />
                <Input
                  id="password"
                  name="password"
                  type="password"
                  required
                  value={formData.password}
                  onChange={handleInputChange}
                  className="pl-10"
                  placeholder="Enter your password"
                  style={{ 
                    backgroundColor: customColors.background,
                    color: customColors.text,
                    borderColor: customColors.muted
                  }}
                  disabled={loading}
                />
              </div>
            </div>
            <Button
              type="submit"
              className="w-full transition-colors duration-200 hover:opacity-90"
              disabled={loading}
              style={{ 
                backgroundColor: customColors.primary,
                color: customColors.background
              }}
            >
              {loading ? (
                <div className="flex items-center gap-2">
                  <Loader2 className="h-4 w-4 animate-spin" />
                  <span>Please wait...</span>
                </div>
              ) : (
                isLogin ? 'Sign In' : 'Create Account'
              )}
            </Button>
          </form>

          <Button
            type="button"
            variant="ghost"
            className="w-full transition-colors duration-200 hover:opacity-90"
            onClick={() => setIsLogin(!isLogin)}
            disabled={loading}
            style={{ 
              color: customColors.text,
              backgroundColor: `${customColors.primary}10`
            }}
          >
            {isLogin ? "Don't have an account? Sign Up" : 'Already have an account? Sign In'}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}