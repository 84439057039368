import { createContext, useContext, useEffect, useState, ReactNode } from 'react';
import { User, AuthError } from '@supabase/supabase-js';
import { supabase } from '@/lib/supabase';
import { useToast } from '@/hooks/use-toast';

type AuthContextType = {
  user: User | null;
  isPro: boolean;
  loading: boolean;
  signIn: (email: string, password: string) => Promise<void>;
  signUp: (email: string, password: string) => Promise<void>;
  signOut: () => Promise<void>;
  upgradeToPro: () => Promise<void>;
};

const AuthContext = createContext<AuthContextType | null>(null);

function getErrorMessage(error: unknown): string {
  if (error instanceof AuthError) {
    switch (error.status) {
      case 400:
        return 'Invalid email or password';
      case 422:
        return 'Email already registered';
      case 500:
        return 'Service temporarily unavailable. Please try again later.';
      case 0:
        return 'Unable to connect. Please check your internet connection.';
      default:
        return error.message;
    }
  }
  return 'An unexpected error occurred';
}

export function AuthProvider({ children }: { children: ReactNode }) {
  const [user, setUser] = useState<User | null>(null);
  const [isPro, setIsPro] = useState(false);
  const [loading, setLoading] = useState(true);
  const { toast } = useToast();

  const checkProStatus = async (userId: string) => {
    try {
      const { data, error } = await supabase
        .from('profiles')
        .select('is_pro')
        .eq('id', userId)
        .single();

      if (error) throw error;
      setIsPro(data?.is_pro ?? false);
    } catch (error) {
      console.error('Error checking pro status:', error);
      setIsPro(false);
    }
  };

  useEffect(() => {
    let mounted = true;

    async function initializeAuth() {
      try {
        // Get initial session
        const { data: { session }, error: sessionError } = await supabase.auth.getSession();
        if (sessionError) throw sessionError;

        if (mounted) {
          if (session?.user) {
            setUser(session.user);
            await checkProStatus(session.user.id);
          }
        }
      } catch (error) {
        console.error('Auth initialization error:', error);
        setUser(null);
        setIsPro(false);
      } finally {
        if (mounted) {
          setLoading(false);
        }
      }
    }

    // Set up auth state listener
    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange(async (event, session) => {
      if (!mounted) return;

      if (session?.user) {
        setUser(session.user);
        await checkProStatus(session.user.id);

        if (event === 'SIGNED_IN') {
          toast({
            title: "Welcome!",
            description: "Successfully signed in",
          });
        }
      } else {
        setUser(null);
        setIsPro(false);
      }
    });

    initializeAuth();

    return () => {
      mounted = false;
      subscription.unsubscribe();
    };
  }, [toast]);

  const signIn = async (email: string, password: string) => {
    try {
      const { data, error } = await supabase.auth.signInWithPassword({
        email: email.trim(),
        password,
      });

      if (error) throw error;

      if (data?.user) {
        toast({
          title: "Success",
          description: "Successfully signed in!",
        });
      }
    } catch (error) {
      console.error('Sign in error:', error);
      toast({
        title: "Sign In Failed",
        description: getErrorMessage(error),
        variant: "destructive",
      });
      throw error;
    }
  };

  const signUp = async (email: string, password: string) => {
    try {
      const { data, error } = await supabase.auth.signUp({
        email: email.trim(),
        password,
        options: {
          emailRedirectTo: `${window.location.origin}/auth/callback`,
          data: {
            email: email.trim(),
          },
        },
      });

      if (error) throw error;

      if (data) {
        toast({
          title: "Success",
          description: "Account created! Please check your email for verification.",
        });
      }
    } catch (error) {
      console.error('Sign up error:', error);
      toast({
        title: "Sign Up Failed",
        description: getErrorMessage(error),
        variant: "destructive",
      });
      throw error;
    }
  };

  const signOut = async () => {
    try {
      // First check if we have a session
      const { data: { session } } = await supabase.auth.getSession();
      
      if (!session) {
        // If no session, just clear the local state
        setUser(null);
        setIsPro(false);
        return;
      }

      const { error } = await supabase.auth.signOut();
      if (error) throw error;

      setUser(null);
      setIsPro(false);

      toast({
        title: "Success",
        description: "Successfully signed out!",
      });
    } catch (error) {
      console.error('Sign out error:', error);
      // Even if there's an error, clear the local state
      setUser(null);
      setIsPro(false);
      
      toast({
        title: "Sign Out Issue",
        description: "You have been signed out, but there was an issue with the server.",
        variant: "default",
      });
    }
  };

  const upgradeToPro = async () => {
    if (!user) {
      toast({
        title: "Error",
        description: "Please sign in to upgrade",
        variant: "destructive",
      });
      return;
    }

    try {
      const { error } = await supabase
        .from('profiles')
        .update({ is_pro: true })
        .eq('id', user.id)
        .single();

      if (error) throw error;

      setIsPro(true);
      toast({
        title: "Success",
        description: "Successfully upgraded to Pro!",
      });
    } catch (error) {
      console.error('Upgrade error:', error);
      toast({
        title: "Upgrade Failed",
        description: getErrorMessage(error),
        variant: "destructive",
      });
      throw error;
    }
  };

  const value: AuthContextType = {
    user,
    isPro,
    loading,
    signIn,
    signUp,
    signOut,
    upgradeToPro,
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  const context = useContext(AuthContext);
  if (context === null) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}