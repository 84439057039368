import { memo } from 'react';
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { ScrollArea } from "@/components/ui/scroll-area";
import { ThemeColors } from '@/types/profit';
import { AIInsight } from '@/hooks/useAIInsights';
import { format } from 'date-fns';
import ReactMarkdown from 'react-markdown';

interface InsightHistoryProps {
  insights: AIInsight[];
  customColors: ThemeColors;
}

export const InsightHistory = memo(function InsightHistory({ 
  insights, 
  customColors 
}: InsightHistoryProps) {
  return (
    <div className="space-y-4">
      {insights.map((insight) => (
        <Card key={insight.id} style={{ backgroundColor: customColors.muted }}>
          <CardHeader>
            <CardTitle className="text-base" style={{ color: customColors.text }}>
              {insight.title}
            </CardTitle>
            <div className="flex justify-between items-center text-sm">
              <span style={{ color: customColors.secondary }}>
                {format(new Date(insight.created_at), 'MMM d, yyyy')}
              </span>
            </div>
          </CardHeader>
          <CardContent>
            <p className="text-sm mb-4" style={{ color: customColors.secondary }}>
              {insight.summary}
            </p>
            <ScrollArea className="h-[200px]">
              <div 
                className="prose prose-neutral dark:prose-invert"
                style={{ color: customColors.text }}
              >
                <ReactMarkdown>{insight.content}</ReactMarkdown>
              </div>
            </ScrollArea>
          </CardContent>
        </Card>
      ))}
    </div>
  );
});