import { memo } from 'react';
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { ThemeColors } from '@/types/profit';
import { useOpportunityStats } from '@/hooks/useOpportunityStats';
import { Loader2, TrendingUp, Bookmark, Star, LineChart } from 'lucide-react';

interface StatsOverviewProps {
  customColors: ThemeColors;
}

export const StatsOverview = memo(function StatsOverview({
  customColors
}: StatsOverviewProps) {
  const { stats, loading } = useOpportunityStats();

  if (loading) {
    return (
      <Card className="mb-6" style={{ backgroundColor: customColors.cardBackground }}>
        <CardContent className="p-6">
          <div className="flex items-center justify-center">
            <Loader2 className="h-6 w-6 animate-spin" style={{ color: customColors.primary }} />
          </div>
        </CardContent>
      </Card>
    );
  }

  const statItems = [
    {
      title: 'Total Opportunities',
      value: stats.totalOpportunities,
      icon: TrendingUp,
      color: customColors.primary
    },
    {
      title: 'Tracked Ideas',
      value: stats.trackedCount,
      icon: Bookmark,
      color: customColors.accent
    },
    {
      title: 'Premium Opportunities',
      value: stats.premiumCount,
      icon: Star,
      color: customColors.warning
    },
    {
      title: 'Average ROI',
      value: stats.averageROI,
      icon: LineChart,
      color: customColors.success
    }
  ];

  return (
    <Card className="mb-6" style={{ backgroundColor: customColors.cardBackground }}>
      <CardHeader>
        <CardTitle style={{ color: customColors.text }}>Investment Overview</CardTitle>
      </CardHeader>
      <CardContent>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
          {statItems.map((item, index) => (
            <Card key={index} style={{ backgroundColor: customColors.muted }}>
              <CardContent className="p-4">
                <div className="flex items-center justify-between space-x-4">
                  <div>
                    <p className="text-sm" style={{ color: customColors.secondary }}>
                      {item.title}
                    </p>
                    <p className="text-2xl font-bold" style={{ color: item.color }}>
                      {item.value}
                    </p>
                  </div>
                  <item.icon className="h-6 w-6" style={{ color: item.color }} />
                </div>
              </CardContent>
            </Card>
          ))}
        </div>
      </CardContent>
    </Card>
  );
});