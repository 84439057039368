import { memo } from 'react';
import {
  XAxis as RechartsXAxis,
  YAxis as RechartsYAxis,
  XAxisProps,
  YAxisProps,
} from 'recharts';

export const XAxis = memo(function XAxis({ 
  stroke = '#666',
  tick = { fill: '#666' },
  tickLine = { stroke: '#666' },
  scale = 'auto',
  allowDecimals = true,
  allowDataOverflow = false,
  allowDuplicatedCategory = true,
  domain = ['auto', 'auto'],
  padding = { left: 0, right: 0 },
  ...props 
}: XAxisProps) {
  return (
    <RechartsXAxis
      stroke={stroke}
      tick={tick}
      tickLine={tickLine}
      scale={scale}
      allowDecimals={allowDecimals}
      allowDataOverflow={allowDataOverflow}
      allowDuplicatedCategory={allowDuplicatedCategory}
      domain={domain}
      padding={padding}
      {...props}
    />
  );
});

export const YAxis = memo(function YAxis({ 
  stroke = '#666',
  tick = { fill: '#666' },
  tickLine = { stroke: '#666' },
  scale = 'auto',
  allowDecimals = true,
  allowDataOverflow = false,
  domain = ['auto', 'auto'],
  padding = { top: 0, bottom: 0 },
  ...props 
}: YAxisProps) {
  return (
    <RechartsYAxis
      stroke={stroke}
      tick={tick}
      tickLine={tickLine}
      scale={scale}
      allowDecimals={allowDecimals}
      allowDataOverflow={allowDataOverflow}
      domain={domain}
      padding={padding}
      {...props}
    />
  );
});