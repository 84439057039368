import { memo } from 'react';
import { Card, CardContent } from "@/components/ui/card";
import { ThemeColors } from '@/types/profit';

interface TutorialProps {
  customColors: ThemeColors;
}

export const Tutorial = memo(function Tutorial({ customColors }: TutorialProps) {
  const steps = [
    {
      title: "Track Opportunities",
      description: "Start by tracking investment opportunities that interest you. Click the bookmark icon on any opportunity card."
    },
    {
      title: "Generate Insights",
      description: "Once you've tracked some opportunities, click 'Generate Insights' to get AI-powered analysis of your portfolio."
    },
    {
      title: "Review Analysis",
      description: "The AI will provide detailed insights including diversification strategy, risk management, and specific action items."
    },
    {
      title: "Historical Analysis",
      description: "Access your previous insights anytime from the History tab to track how your investment strategy evolves."
    }
  ];

  const faqs = [
    {
      question: "How does the AI analysis work?",
      answer: "Our AI analyzes your tracked opportunities using advanced algorithms to provide personalized investment insights, considering factors like risk levels, ROI, and market trends."
    },
    {
      question: "How often should I generate new insights?",
      answer: "We recommend generating new insights whenever you make significant changes to your tracked opportunities or at least monthly to stay updated with market changes."
    },
    {
      question: "Are the insights financial advice?",
      answer: "No, the AI insights are for informational purposes only and should not be considered as financial advice. Always consult with a qualified financial advisor for personal investment decisions."
    }
  ];

  return (
    <div className="space-y-8">
      <div>
        <h3 
          className="text-lg font-semibold mb-4"
          style={{ color: customColors.text }}
        >
          How It Works
        </h3>
        <div className="grid gap-4">
          {steps.map((step, index) => (
            <Card key={index} style={{ backgroundColor: customColors.muted }}>
              <CardContent className="p-4">
                <h4 
                  className="font-medium mb-2"
                  style={{ color: customColors.text }}
                >
                  {index + 1}. {step.title}
                </h4>
                <p 
                  className="text-sm"
                  style={{ color: customColors.secondary }}
                >
                  {step.description}
                </p>
              </CardContent>
            </Card>
          ))}
        </div>
      </div>

      <div>
        <h3 
          className="text-lg font-semibold mb-4"
          style={{ color: customColors.text }}
        >
          Frequently Asked Questions
        </h3>
        <div className="space-y-4">
          {faqs.map((faq, index) => (
            <Card key={index} style={{ backgroundColor: customColors.muted }}>
              <CardContent className="p-4">
                <h4 
                  className="font-medium mb-2"
                  style={{ color: customColors.text }}
                >
                  {faq.question}
                </h4>
                <p 
                  className="text-sm"
                  style={{ color: customColors.secondary }}
                >
                  {faq.answer}
                </p>
              </CardContent>
            </Card>
          ))}
        </div>
      </div>
    </div>
  );
});