import { memo } from 'react';
import { Card, CardContent } from "@/components/ui/card";
import { ThemeColors, Opportunity } from '@/types/profit';
import { LineChartComponent } from '../charts/LineChartComponent';
import { BarChartComponent } from '../charts/BarChartComponent';
import { formatCurrency } from '@/utils/formatUtils';
import { normalizeRiskLevel } from '@/utils/profitUtils';

interface TrackedAnalyticsProps {
  opportunities: Opportunity[];
  customColors: ThemeColors;
}

export const TrackedAnalytics = memo(function TrackedAnalytics({
  opportunities,
  customColors
}: TrackedAnalyticsProps) {
  const totalValue = opportunities.reduce((sum, opp) => 
    sum + parseFloat(opp.volume.replace(/[^0-9.-]+/g, '')), 
    0
  );

  const averageROI = opportunities.reduce((sum, opp) => 
    sum + parseFloat(opp.roi), 
    0
  ) / Math.max(opportunities.length, 1);

  // Initialize with all possible risk levels
  const riskDistribution = opportunities.reduce((acc, opp) => {
    const normalizedRisk = normalizeRiskLevel(opp.riskLevel);
    acc[normalizedRisk] = (acc[normalizedRisk] || 0) + 1;
    return acc;
  }, {
    'LOW': 0,
    'MEDIUM': 0,
    'HIGH': 0
  } as Record<string, number>);

  // Initialize with all possible categories
  const categoryDistribution = opportunities.reduce((acc, opp) => {
    const category = opp.category.toUpperCase();
    acc[category] = (acc[category] || 0) + 1;
    return acc;
  }, {} as Record<string, number>);

  // Convert to chart data format with numeric x values and proper labels
  const riskData = Object.entries(riskDistribution).map(([label, value], index) => ({ 
    x: index,
    y: value,
    label: label.charAt(0) + label.slice(1).toLowerCase()
  }));

  const categoryData = Object.entries(categoryDistribution)
    .filter(([_, value]) => value > 0) // Only show categories with values
    .map(([label, value], index) => ({ 
      x: index,
      y: value,
      label: label.charAt(0) + label.slice(1).toLowerCase()
    }));
  
  const roiTrend = opportunities
    .sort((a, b) => parseFloat(a.roi) - parseFloat(b.roi))
    .map((opp, index) => ({ 
      x: index, 
      y: parseFloat(opp.roi),
      label: opp.title
    }));

  return (
    <div className="grid gap-6">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <Card style={{ backgroundColor: customColors.muted }}>
          <CardContent className="p-6">
            <h3 className="text-lg font-semibold mb-4" style={{ color: customColors.text }}>
              Portfolio Value
            </h3>
            <div className="text-3xl font-bold mb-2" style={{ color: customColors.primary }}>
              {formatCurrency(totalValue)}
            </div>
            <div className="text-sm" style={{ color: customColors.secondary }}>
              Average ROI: {averageROI.toFixed(1)}%
            </div>
          </CardContent>
        </Card>

        <Card style={{ backgroundColor: customColors.muted }}>
          <CardContent className="p-6">
            <h3 className="text-lg font-semibold mb-4" style={{ color: customColors.text }}>
              ROI Distribution
            </h3>
            <LineChartComponent
              data={roiTrend}
              dataKey="y"
              height={150}
              customColors={customColors}
            />
          </CardContent>
        </Card>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <Card style={{ backgroundColor: customColors.muted }}>
          <CardContent className="p-6">
            <h3 className="text-lg font-semibold mb-4" style={{ color: customColors.text }}>
              Risk Level Distribution
            </h3>
            <BarChartComponent
              data={riskData}
              dataKey="y"
              height={200}
              customColors={customColors}
            />
          </CardContent>
        </Card>

        {categoryData.length > 0 && (
          <Card style={{ backgroundColor: customColors.muted }}>
            <CardContent className="p-6">
              <h3 className="text-lg font-semibold mb-4" style={{ color: customColors.text }}>
                Category Distribution
              </h3>
              <BarChartComponent
                data={categoryData}
                dataKey="y"
                height={200}
                customColors={customColors}
              />
            </CardContent>
          </Card>
        )}
      </div>
    </div>
  );
});