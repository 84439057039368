import { TrendData, RiskLevel } from '@/types/profit';

export function generateProfitData(
  startValue: number,
  volatility: number,
  points: number,
  trend: 'up' | 'down' | 'volatile' = 'up'
): TrendData[] {
  let value = startValue;
  return Array.from({ length: points }, (_, i) => {
    const random = Math.random() - 0.5;
    const trendFactor = trend === 'up' ? 0.1 : trend === 'down' ? -0.1 : 0;
    value += (random * volatility) + trendFactor;
    return {
      x: i,
      y: Math.max(0, parseFloat(value.toFixed(2))),
      roi: parseFloat(((value / startValue - 1) * 100).toFixed(2))
    };
  });
}

export function normalizeRiskLevel(risk: string): RiskLevel {
  const level = risk.toUpperCase();
  switch (level) {
    case 'LOW':
    case 'MEDIUM':
    case 'HIGH':
      return level as RiskLevel;
    default:
      return 'MEDIUM';
  }
}

export function getRiskColor(level: string, colors: Record<string, string>) {
  switch (normalizeRiskLevel(level)) {
    case 'LOW':
      return colors.success;
    case 'MEDIUM':
      return colors.warning;
    case 'HIGH':
      return colors.danger;
    default:
      return colors.primary;
  }
}