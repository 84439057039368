export const TOAST_LIMIT = 1;
export const TOAST_REMOVE_DELAY = 1000000;

export const timelineOptions = ["All Time", "6 Months", "1 Year", "2+ Years"] as const;
export const categoryOptions = [
  "All Categories",
  "Technology",
  "Cleantech",
  "Crypto",
  "Agritech",
  "Healthtech",
  "Telecom",
  "Logistics",
  "Edtech",
  "Infrastructure"
] as const;
export const riskLevelOptions = ["All Risks", "Low", "Medium", "High"] as const;
export const profitRangeOptions = ["All Ranges", "0-20%", "20-40%", "40%+"] as const;