import { StrictMode } from 'react';
import ProfitAgent from './components/ProfitAgent';
import { ThemeProvider } from './providers/ThemeProvider';
import { AuthProvider } from './providers/AuthProvider';
import { SupabaseProvider } from './providers/SupabaseProvider';
import { Toaster } from "@/components/ui/toaster";

function App() {
  return (
    <StrictMode>
      <SupabaseProvider>
        <AuthProvider>
          <ThemeProvider>
            <ProfitAgent />
            <Toaster />
          </ThemeProvider>
        </AuthProvider>
      </SupabaseProvider>
    </StrictMode>
  );
}

export default App;