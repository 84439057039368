import { useEffect, useState, useCallback } from 'react';
import { supabase } from '@/lib/supabase';
import { useAuth } from '@/providers/AuthProvider';
import { useToast } from '@/hooks/use-toast';

export function useTrackedOpportunities() {
  const { user } = useAuth();
  const [trackedIds, setTrackedIds] = useState<number[]>([]);
  const [loading, setLoading] = useState(false);
  const { toast } = useToast();

  const fetchTrackedOpportunities = useCallback(async () => {
    if (!user) {
      setTrackedIds([]);
      return;
    }

    setLoading(true);
    try {
      const { data, error } = await supabase
        .from('tracked_opportunities')
        .select('opportunity_id')
        .eq('user_id', user.id);

      if (error) throw error;
      
      if (data) {
        setTrackedIds(data.map(item => item.opportunity_id));
      }
    } catch (error) {
      console.error('Error fetching tracked opportunities:', error);
    } finally {
      setLoading(false);
    }
  }, [user]);

  const toggleTrackedOpportunity = async (opportunityId: number) => {
    if (!user) {
      toast({
        title: "Authentication Required",
        description: "Please sign in to track opportunities",
        variant: "destructive",
      });
      return;
    }

    try {
      const isCurrentlyTracked = trackedIds.includes(opportunityId);

      if (isCurrentlyTracked) {
        await supabase
          .from('tracked_opportunities')
          .delete()
          .eq('user_id', user.id)
          .eq('opportunity_id', opportunityId);

        setTrackedIds(prev => prev.filter(id => id !== opportunityId));
      } else {
        const { error } = await supabase
          .from('tracked_opportunities')
          .insert({
            user_id: user.id,
            opportunity_id: opportunityId,
            notes: null
          });

        if (error) throw error;
        setTrackedIds(prev => [...prev, opportunityId]);
      }
    } catch (error) {
      console.error('Error updating tracked opportunity:', error);
      toast({
        title: "Error",
        description: "Failed to update tracking status",
        variant: "destructive",
      });
    }
  };

  useEffect(() => {
    fetchTrackedOpportunities();
  }, [fetchTrackedOpportunities]);

  return {
    trackedIds,
    loading,
    toggleTrackedOpportunity
  };
}