import { useState, useCallback, useEffect } from 'react';
import { openai } from '@/lib/openai';
import { supabase } from '@/lib/supabase';
import { useAuth } from '@/providers/AuthProvider';
import { useToast } from '@/hooks/use-toast';
import { Opportunity } from '@/types/profit';

export interface AIInsight {
  id: number;
  content: string;
  title: string;
  summary: string;
  opportunities: number[];
  created_at: string;
}

export function useAIInsights() {
  const [loading, setLoading] = useState(false);
  const [insights, setInsights] = useState<AIInsight[]>([]);
  const { user } = useAuth();
  const { toast } = useToast();

  const fetchInsights = useCallback(async () => {
    if (!user) return;

    try {
      const { data, error } = await supabase
        .from('ai_insights')
        .select('*')
        .eq('user_id', user.id)
        .order('created_at', { ascending: false });

      if (error) throw error;
      setInsights(data || []);
    } catch (error) {
      console.error('Error fetching insights:', error);
    }
  }, [user]);

  useEffect(() => {
    fetchInsights();
  }, [fetchInsights]);

  const generateInsight = useCallback(async (opportunities: Opportunity[]) => {
    if (!user) {
      toast({
        title: "Authentication Required",
        description: "Please sign in to use AI insights",
        variant: "destructive",
      });
      return null;
    }
    
    setLoading(true);
    try {
      const prompt = `Analyze these investment opportunities and provide strategic insights:
      ${opportunities.map(o => `
        Title: ${o.title}
        ROI: ${o.roi}
        Risk Level: ${o.riskLevel}
        Category: ${o.category}
        Description: ${o.description}
      `).join('\n')}

      Provide a detailed analysis including:
      1. Portfolio Diversification Strategy
      2. Risk Management Recommendations
      3. Growth Opportunities
      4. Market Timing Considerations
      5. Specific Action Items

      Also provide:
      - A concise title for this analysis (max 60 chars)
      - A brief summary (max 150 chars)

      Format the response as JSON with the following structure:
      {
        "title": "string",
        "summary": "string",
        "analysis": "markdown formatted string"
      }`;

      const completion = await openai.chat.completions.create({
        messages: [{ role: "user", content: prompt }],
        model: "gpt-4-turbo-preview",
        temperature: 0.7,
        max_tokens: 1000,
        response_format: { type: "json_object" }
      });

      const response = JSON.parse(completion.choices[0].message.content || "{}");
      
      if (response) {
        // Store in Supabase
        const { data, error } = await supabase
          .from('ai_insights')
          .insert({
            user_id: user.id,
            content: response.analysis,
            title: response.title,
            summary: response.summary,
            opportunities: opportunities.map(o => o.id)
          })
          .select()
          .single();

        if (error) throw error;

        if (data) {
          setInsights(prev => [data, ...prev]);
        }

        toast({
          title: "Success",
          description: "AI insights generated successfully",
        });

        return response.analysis;
      }

      return null;
    } catch (error: any) {
      console.error('Error generating AI insight:', error);
      toast({
        title: "Error",
        description: error?.message || "Failed to generate AI insights. Please try again.",
        variant: "destructive",
      });
      return null;
    } finally {
      setLoading(false);
    }
  }, [user, toast]);

  return {
    generateInsight,
    insights,
    loading
  };
}