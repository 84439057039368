import { memo, useState } from 'react';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Switch } from "@/components/ui/switch";
import { ThemeColors } from '@/types/profit';
import { useAuth } from '@/providers/AuthProvider';
import { Bell, Mail, Shield, Trash2 } from 'lucide-react';

interface UserSettingsProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  customColors: ThemeColors;
}

export const UserSettings = memo(function UserSettings({
  open,
  onOpenChange,
  customColors
}: UserSettingsProps) {
  const { user } = useAuth();
  const [notifications, setNotifications] = useState(true);
  const [emailUpdates, setEmailUpdates] = useState(true);

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent 
        className="max-w-md"
        style={{ backgroundColor: customColors.cardBackground }}
      >
        <DialogHeader>
          <DialogTitle style={{ color: customColors.text }}>Account Settings</DialogTitle>
        </DialogHeader>

        <div className="grid gap-6">
          <div className="space-y-2">
            <Label style={{ color: customColors.text }}>Email</Label>
            <Input 
              value={user?.email || ''} 
              disabled 
              style={{ 
                backgroundColor: customColors.muted,
                color: customColors.text
              }}
            />
          </div>

          <div className="space-y-4">
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-2">
                <Bell className="h-4 w-4" style={{ color: customColors.primary }} />
                <Label style={{ color: customColors.text }}>Push Notifications</Label>
              </div>
              <Switch 
                checked={notifications}
                onCheckedChange={setNotifications}
              />
            </div>

            <div className="flex items-center justify-between">
              <div className="flex items-center gap-2">
                <Mail className="h-4 w-4" style={{ color: customColors.primary }} />
                <Label style={{ color: customColors.text }}>Email Updates</Label>
              </div>
              <Switch 
                checked={emailUpdates}
                onCheckedChange={setEmailUpdates}
              />
            </div>
          </div>

          <div className="space-y-4 pt-4 border-t" style={{ borderColor: customColors.muted }}>
            <Button 
              variant="outline"
              className="w-full"
              style={{ 
                backgroundColor: `${customColors.primary}10`,
                color: customColors.text,
                borderColor: customColors.muted
              }}
            >
              <Shield className="h-4 w-4 mr-2" />
              Change Password
            </Button>

            <Button 
              variant="destructive"
              className="w-full"
            >
              <Trash2 className="h-4 w-4 mr-2" />
              Delete Account
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
});