import { memo } from 'react';
import { ResponsiveContainer, BarChart, CartesianGrid, Tooltip, Bar, XAxis, YAxis } from 'recharts';
import { ThemeColors } from '@/types/profit';
import { defaultChartMargin, defaultTooltipStyle } from './ChartDefaults';

interface ChartData {
  x: string | number; // Allow both string and number
  y: number;
  label?: string;
}


interface BarChartComponentProps {
  data: ChartData[];
  dataKey: string;
  height?: number;
  customColors: ThemeColors;
  radius?: [number, number, number, number];
}

export const BarChartComponent = memo(function BarChartComponent({
  data,
  dataKey,
  height = 200,
  customColors,
  radius = [4, 4, 0, 0]
}: BarChartComponentProps) {
  // Log the data to verify its contents
  console.log('BarChartComponent data:', data);

  const axisProps = {
    stroke: customColors.secondary,
    tick: { fill: customColors.secondary },
    tickLine: { stroke: customColors.secondary },
  };

  return (
    <div style={{ height: `${height}px` }}>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart data={data} margin={defaultChartMargin}>
          <CartesianGrid strokeDasharray="3 3" stroke={customColors.muted} />
          <XAxis 
            {...axisProps} 
            dataKey="x"
            tickFormatter={(value: number) => data.find(d => d.x === value)?.label || value.toString()}
          />
          <YAxis {...axisProps} />
          <Tooltip 
            contentStyle={{ 
              ...defaultTooltipStyle,
              backgroundColor: customColors.cardBackground, 
              borderColor: customColors.muted 
            }}
            labelStyle={{ color: customColors.text }}
            labelFormatter={(value: number) => data.find(d => d.x === value)?.label || value.toString()}
          />
          <Bar 
            dataKey={dataKey} 
            fill={customColors.primary} 
            radius={radius}
            isAnimationActive={false}
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
});