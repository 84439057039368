import { memo } from 'react';
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { ThemeColors } from '@/types/profit';
import { useAuth } from '@/providers/AuthProvider';
import { BookMarked, Star, Settings, TrendingUp, Sparkles } from 'lucide-react';
import { useTrackedOpportunities } from '@/hooks/useTrackedOpportunities';
import { LineChartComponent } from '../charts/LineChartComponent';
import { generateProfitData } from '@/utils/profitUtils';
import { TrackedOpportunitiesView } from './TrackedOpportunitiesView';

interface UserDashboardProps {
  customColors: ThemeColors;
  onShowSettings: () => void;
  onShowAIInsights: () => void;
}

export const UserDashboard = memo(function UserDashboard({
  customColors,
  onShowSettings,
  onShowAIInsights
}: UserDashboardProps) {
  const { user, isPro } = useAuth();
  const { trackedIds } = useTrackedOpportunities();
  const mockProfitData = generateProfitData(100, 15, 30, 'up');

  return (
    <div className="mb-8">
      <div className="flex items-center justify-between mb-6">
        <div>
          <h2 className="text-2xl font-bold" style={{ color: customColors.text }}>
            Welcome back, {user?.email?.split('@')[0]}!
          </h2>
          <p style={{ color: customColors.secondary }}>
            Your investment dashboard
          </p>
        </div>
        <div className="flex gap-2">
          <Button
            variant="outline"
            onClick={onShowAIInsights}
            style={{ 
              backgroundColor: `${customColors.primary}10`,
              color: customColors.text
            }}
          >
            <Sparkles className="h-4 w-4 mr-2" />
            AI Insights
          </Button>
          <Button
            variant="outline"
            onClick={onShowSettings}
            style={{ 
              backgroundColor: `${customColors.primary}10`,
              color: customColors.text
            }}
          >
            <Settings className="h-4 w-4 mr-2" />
            Settings
          </Button>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
        <Card style={{ backgroundColor: customColors.cardBackground }}>
          <CardHeader>
            <CardTitle className="flex items-center gap-2" style={{ color: customColors.text }}>
              <BookMarked className="h-5 w-5" style={{ color: customColors.primary }} />
              Tracked Opportunities
            </CardTitle>
          </CardHeader>
          <CardContent>
            <div className="text-3xl font-bold" style={{ color: customColors.primary }}>
              {trackedIds.length}
            </div>
            <p className="text-sm" style={{ color: customColors.secondary }}>
              Active investments being monitored
            </p>
          </CardContent>
        </Card>

        <Card style={{ backgroundColor: customColors.cardBackground }}>
          <CardHeader>
            <CardTitle className="flex items-center gap-2" style={{ color: customColors.text }}>
              <TrendingUp className="h-5 w-5" style={{ color: customColors.success }} />
              Portfolio Performance
            </CardTitle>
          </CardHeader>
          <CardContent>
            <div className="h-[100px]">
              <LineChartComponent
                data={mockProfitData}
                dataKey="y"
                customColors={customColors}
                height={100}
              />
            </div>
          </CardContent>
        </Card>

        <Card style={{ backgroundColor: customColors.cardBackground }}>
          <CardHeader>
            <CardTitle className="flex items-center gap-2" style={{ color: customColors.text }}>
              <Star className="h-5 w-5" style={{ color: customColors.warning }} />
              Account Status
            </CardTitle>
          </CardHeader>
          <CardContent>
            <div className="text-xl font-bold" style={{ color: customColors.warning }}>
              {isPro ? 'Pro Member' : 'Free Plan'}
            </div>
            {!isPro && (
              <p className="text-sm" style={{ color: customColors.secondary }}>
                Upgrade to access premium features
              </p>
            )}
          </CardContent>
        </Card>
      </div>

      <TrackedOpportunitiesView customColors={customColors} />
    </div>
  );
});