import { Opportunity } from '@/types/profit';
import { generateProfitData } from '@/utils/profitUtils';

export const profitData: Opportunity[] = [
  {
    id: 1,
    title: "Kim AI-Powered Trading Systems",
    volume: "$450K",
    growth: "+125%",
    roi: "35%",
    riskLevel: "MEDIUM",
    timeline: "6-12 months",
    category: "TECHNOLOGY",
    description: "Automated trading systems utilizing artificial intelligence for market analysis and execution",
    longDescription: "AI-powered trading systems are revolutionizing the financial markets. These systems use machine learning algorithms to analyze market data, identify patterns, and execute trades automatically. With the ability to process vast amounts of data in real-time, these systems can spot opportunities and risks faster than human traders.",
    profitMetrics: {
      initialInvestment: "$10,000",
      projectedAnnualReturn: "35%",
      breakevenPeriod: "4 months",
      marketSize: "$12B",
      potentialProfit: "$3,500 - $5,000 per year",
      riskFactors: ["Market volatility", "Algorithm reliability", "Regulatory changes"],
      successRate: "72%"
    },
    trend: generateProfitData(100, 15, 50, 'up'),
    status: "TRENDING",
    isPremium: true,
    icon: "TrendingUp"
  },
  {
    id: 2,
    title: "Green Energy Storage Solutions",
    volume: "$890K",
    growth: "+85%",
    roi: "28%",
    riskLevel: "LOW",
    timeline: "1-2 years",
    category: "CLEANTECH",
    description: "Investment opportunities in renewable energy storage technologies and grid-scale solutions",
    longDescription: "The green energy storage market is experiencing rapid growth as the world transitions to renewable energy sources. Battery technology improvements and increasing demand for grid-scale storage solutions are creating numerous investment opportunities.",
    profitMetrics: {
      initialInvestment: "$25,000",
      projectedAnnualReturn: "28%",
      breakevenPeriod: "8 months",
      marketSize: "$8.5B",
      potentialProfit: "$7,000 - $10,000 per year",
      riskFactors: ["Technology obsolescence", "Government policy changes", "Competition"],
      successRate: "85%"
    },
    trend: generateProfitData(80, 10, 50, 'up'),
    status: "EXPLODING",
    isPremium: false,
    icon: "Leaf"
  },
  {
    id: 3,
    title: "Precision Agriculture Technology",
    volume: "$380K",
    growth: "+70%",
    roi: "25%",
    riskLevel: "LOW",
    timeline: "2-3 years",
    category: "AGRITECH",
    description: "Smart farming solutions using IoT and data analytics for optimal crop yields",
    longDescription: "Precision agriculture technology is transforming the farming industry by optimizing crop yields and reducing resource waste. These solutions incorporate IoT sensors, drones, and data analytics to provide farmers with real-time insights.",
    profitMetrics: {
      initialInvestment: "$20,000",
      projectedAnnualReturn: "25%",
      breakevenPeriod: "10 months",
      marketSize: "$6.5B",
      potentialProfit: "$5,000 - $7,000 per year",
      riskFactors: ["Weather dependencies", "Adoption rate", "Setup costs"],
      successRate: "82%"
    },
    trend: generateProfitData(70, 8, 50, 'up'),
    status: "GROWING",
    isPremium: false,
    icon: "Wheat"
  },
  {
    id: 4,
    title: "Digital Health Platforms",
    volume: "$720K",
    growth: "+95%",
    roi: "32%",
    riskLevel: "MEDIUM",
    timeline: "1-2 years",
    category: "HEALTHTECH",
    description: "Telemedicine and digital health solutions revolutionizing healthcare delivery",
    longDescription: "Digital health platforms are transforming healthcare delivery through telemedicine, AI diagnostics, and personalized medicine. The sector is experiencing rapid growth due to increased adoption of remote healthcare solutions.",
    profitMetrics: {
      initialInvestment: "$15,000",
      projectedAnnualReturn: "32%",
      breakevenPeriod: "6 months",
      marketSize: "$15B",
      potentialProfit: "$4,800 - $6,500 per year",
      riskFactors: ["Regulatory compliance", "Data security", "Market competition"],
      successRate: "78%"
    },
    trend: generateProfitData(90, 12, 50, 'up'),
    status: "TRENDING",
    isPremium: true,
    icon: "Stethoscope"
  },
  {
    id: 5,
    title: "Quantum Computing Services",
    volume: "$1.2M",
    growth: "+150%",
    roi: "40%",
    riskLevel: "HIGH",
    timeline: "2-4 years",
    category: "TECHNOLOGY",
    description: "Next-generation computing solutions for complex problem-solving",
    longDescription: "Quantum computing represents the next frontier in computational power. Early investments in quantum computing services and applications offer significant growth potential as the technology matures.",
    profitMetrics: {
      initialInvestment: "$50,000",
      projectedAnnualReturn: "40%",
      breakevenPeriod: "12 months",
      marketSize: "$20B",
      potentialProfit: "$20,000 - $25,000 per year",
      riskFactors: ["Technology risks", "Long development cycles", "Market adoption"],
      successRate: "65%"
    },
    trend: generateProfitData(120, 20, 50, 'volatile'),
    status: "EMERGING",
    isPremium: true,
    icon: "Cpu"
  },
  {
    id: 6,
    title: "EdTech Learning Platforms",
    volume: "$550K",
    growth: "+80%",
    roi: "30%",
    riskLevel: "LOW",
    timeline: "1-2 years",
    category: "EDTECH",
    description: "Digital learning solutions with AI-powered personalization",
    longDescription: "Educational technology platforms are revolutionizing learning through personalized content delivery and adaptive learning algorithms. The sector shows strong growth potential due to increasing demand for remote and hybrid learning solutions.",
    profitMetrics: {
      initialInvestment: "$18,000",
      projectedAnnualReturn: "30%",
      breakevenPeriod: "7 months",
      marketSize: "$10B",
      potentialProfit: "$5,400 - $7,200 per year",
      riskFactors: ["User adoption", "Content quality", "Platform scalability"],
      successRate: "80%"
    },
    trend: generateProfitData(85, 10, 50, 'up'),
    status: "GROWING",
    isPremium: false,
    icon: "GraduationCap"
  },
  {
    id: 7,
    title: "Blockchain Infrastructure",
    volume: "$980K",
    growth: "+110%",
    roi: "38%",
    riskLevel: "HIGH",
    timeline: "1-3 years",
    category: "TECHNOLOGY",
    description: "Enterprise blockchain solutions and decentralized infrastructure",
    longDescription: "Blockchain infrastructure is becoming critical for enterprise applications, supply chain management, and decentralized finance. Investment opportunities exist in both platform development and application layers.",
    profitMetrics: {
      initialInvestment: "$30,000",
      projectedAnnualReturn: "38%",
      breakevenPeriod: "9 months",
      marketSize: "$18B",
      potentialProfit: "$11,400 - $15,000 per year",
      riskFactors: ["Technical complexity", "Regulatory uncertainty", "Network effects"],
      successRate: "70%"
    },
    trend: generateProfitData(110, 18, 50, 'volatile'),
    status: "TRENDING",
    isPremium: true,
    icon: "Fingerprint"
  },
  {
    id: 8,
    title: "Smart City Solutions",
    volume: "$670K",
    growth: "+75%",
    roi: "27%",
    riskLevel: "MEDIUM",
    timeline: "2-4 years",
    category: "INFRASTRUCTURE",
    description: "Urban infrastructure optimization using IoT and AI",
    longDescription: "Smart city technologies are transforming urban management through IoT sensors, data analytics, and automated systems. Growing urbanization and the need for efficient city management drive market expansion.",
    profitMetrics: {
      initialInvestment: "$22,000",
      projectedAnnualReturn: "27%",
      breakevenPeriod: "11 months",
      marketSize: "$14B",
      potentialProfit: "$5,940 - $8,000 per year",
      riskFactors: ["Project complexity", "Government partnerships", "Implementation time"],
      successRate: "75%"
    },
    trend: generateProfitData(75, 9, 50, 'up'),
    status: "EMERGING",
    isPremium: false,
    icon: "Briefcase"
  }
];