import { memo } from 'react';
import { Star, Check } from 'lucide-react';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription } from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { ThemeColors } from '@/types/profit';

interface ProModalProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  onUpgrade: () => void;
  customColors: ThemeColors;
}

export const ProModal = memo(function ProModal({
  open,
  onOpenChange,
  onUpgrade,
  customColors,
}: ProModalProps) {
  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent
        className="max-w-md"
        style={{
          backgroundColor: customColors.cardBackground,
          color: customColors.text
        }}
      >
        <DialogHeader>
          <DialogTitle className="flex items-center gap-2 text-2xl font-bold">
            <div 
              className="rounded-lg p-2"
              style={{ 
                background: `linear-gradient(135deg, ${customColors.primary}20, ${customColors.primary}40)`,
                boxShadow: `0 0 20px ${customColors.primary}10`
              }}
            >
              <Star className="h-6 w-6" style={{ color: customColors.primary }} />
            </div>
            Upgrade to Pro
          </DialogTitle>
          <DialogDescription style={{ color: customColors.secondary }}>
            Unlock premium features and maximize your profits with advanced automation
          </DialogDescription>
        </DialogHeader>

        <div className="grid gap-4 pt-4">
          {[
            "Advanced AI-driven profit optimization",
            "Personalized automation strategies",
            "Real-time market analysis and alerts",
            "Exclusive high-profit opportunities"
          ].map((feature, index) => (
            <div key={index} className="flex items-center gap-3">
              <div 
                className="rounded-full p-1"
                style={{ backgroundColor: `${customColors.success}20` }}
              >
                <Check className="h-4 w-4" style={{ color: customColors.success }} />
              </div>
              <span style={{ color: customColors.text }}>{feature}</span>
            </div>
          ))}
          <div className="mt-4 flex gap-4">
            <Button 
              variant="premium"
              className="flex-1 transition-colors duration-200 hover:opacity-90"
              onClick={onUpgrade}
              style={{
                backgroundColor: customColors.primary,
                color: customColors.background
              }}
            >
              Upgrade Now - $29.99/month
            </Button>
            <Button
              variant="outline"
              onClick={() => onOpenChange(false)}
              style={{ 
                backgroundColor: `${customColors.primary}10`,
                color: customColors.text,
                borderColor: customColors.muted
              }}
              className="transition-colors duration-200 hover:opacity-90"
            >
              Close
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
});