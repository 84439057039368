import { memo } from 'react';
import { ResponsiveContainer, LineChart, CartesianGrid, Tooltip, Line } from 'recharts';
import { ThemeColors } from '@/types/profit';
import { defaultChartMargin, defaultTooltipStyle } from './ChartDefaults';
import { XAxis, YAxis } from './ChartComponents';

interface LineChartComponentProps {
  data: Array<{ x: number; y: number }>;
  dataKey: string;
  height?: number;
  customColors: ThemeColors;
  strokeWidth?: number;
}

export const LineChartComponent = memo(function LineChartComponent({
  data,
  dataKey,
  height = 200,
  customColors,
  strokeWidth = 2
}: LineChartComponentProps) {
  const axisProps = {
    stroke: customColors.secondary,
    tick: { fill: customColors.secondary },
    tickLine: { stroke: customColors.secondary },
  };

  return (
    <div style={{ height: `${height}px` }}>
      <ResponsiveContainer width="100%" height="100%">
        <LineChart data={data} margin={defaultChartMargin}>
          <CartesianGrid strokeDasharray="3 3" stroke={customColors.muted} />
          <XAxis {...axisProps} dataKey="x" />
          <YAxis {...axisProps} />
          <Tooltip 
            contentStyle={{ 
              ...defaultTooltipStyle,
              backgroundColor: customColors.cardBackground, 
              borderColor: customColors.muted 
            }}
            labelStyle={{ color: customColors.text }}
          />
          <Line 
            type="monotone" 
            dataKey={dataKey} 
            stroke={customColors.accent}
            strokeWidth={strokeWidth}
            isAnimationActive={false}
            dot={false}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
});