import { useState } from 'react';
import { useTheme } from '@/providers/ThemeProvider';
import { useAuth } from '@/providers/AuthProvider';
import { useOpportunities } from '@/hooks/useOpportunities';
import { useTrackedOpportunities } from '@/hooks/useTrackedOpportunities';
import { useAIInsights } from '@/hooks/useAIInsights';
import { Header } from './Header';
import { Filters } from './Filters';
import { OpportunityGrid } from './OpportunityGrid';
import { ProModal } from './ProModal';
import { AuthModal } from '../auth/AuthModal';
import { UserDashboard } from './UserDashboard';
import { UserSettings } from './UserSettings';
import { AutomationProgress } from '../AutomationProgress';
import { StatsOverview } from './StatsOverview';
import { DailyForecast } from './DailyForecast';
import { AIInsightsModal } from './AIInsightsModal';
import { generateProfitData } from '@/utils/profitUtils';
import { timelineOptions, categoryOptions, riskLevelOptions, profitRangeOptions } from '@/config/constants';
import { useProfitData } from '@/hooks/useProfitData';
import { Loader2 } from "lucide-react";

export default function ProfitAgent() {
  const { colors, theme, toggleTheme } = useTheme();
  const { user, isPro, upgradeToPro } = useAuth();
  const { opportunities, loading: loadingOpportunities } = useOpportunities();
  const { trackedIds, toggleTrackedOpportunity } = useTrackedOpportunities();
  const { generateInsight, insights, loading: insightLoading } = useAIInsights();
  
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedTimeline, setSelectedTimeline] = useState<"All Time" | "6 Months" | "1 Year" | "2+ Years">(timelineOptions[0]);
  const [selectedCategory, setSelectedCategory] = useState<typeof categoryOptions[number]>(categoryOptions[0]);
  const [selectedRiskLevel, setSelectedRiskLevel] = useState<typeof riskLevelOptions[number]>(riskLevelOptions[0]);
  const [selectedProfitRange, setSelectedProfitRange] = useState<typeof profitRangeOptions[number]>(profitRangeOptions[0]);
  const [showProModal, setShowProModal] = useState(false);
  const [showAuthModal, setShowAuthModal] = useState(false);
  const [showSettings, setShowSettings] = useState(false);
  const [showAIInsights, setShowAIInsights] = useState(false);
  const [currentInsight, setCurrentInsight] = useState<string | null>(null);
  const [automationProgress] = useState(75);
  const [chartData] = useState(generateProfitData(100, 15, 50, 'up'));

  const filteredOpportunities = useProfitData({
    opportunities,
    searchQuery,
    selectedCategory,
    selectedRiskLevel,
    selectedProfitRange,
  });

  const trackedOpportunities = opportunities.filter(opp => trackedIds.includes(opp.id));

  const handleResetFilters = () => {
    setSearchQuery("");
    setSelectedTimeline(timelineOptions[0]);
    setSelectedCategory(categoryOptions[0]);
    setSelectedRiskLevel(riskLevelOptions[0]);
    setSelectedProfitRange(profitRangeOptions[0]);
  };

  const handleGenerateInsight = async () => {
    const insight = await generateInsight(trackedOpportunities);
    if (insight) {
      setCurrentInsight(insight);
      setShowAIInsights(true);
    }
  };

  const handleUpgrade = async () => {
    if (!user) {
      setShowAuthModal(true);
      return;
    }
    try {
      await upgradeToPro();
      setShowProModal(false);
    } catch (error) {
      console.error('Error upgrading to pro:', error);
    }
  };

  if (loadingOpportunities) {
    return (
      <div className="fixed inset-0 flex items-center justify-center" style={{ backgroundColor: colors.background }}>
        <div className="flex flex-col items-center gap-4 p-4 rounded-lg text-center">
          <Loader2 className="h-12 w-12 animate-spin" style={{ color: colors.primary }} />
          <div className="text-xl font-medium" style={{ color: colors.text }}>
            Loading investment opportunities...
          </div>
          <div className="text-sm" style={{ color: colors.secondary }}>
            Please wait while we fetch the latest data
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen transition-colors duration-200" style={{ backgroundColor: colors.background }}>
      <Header 
        colors={colors}
        theme={theme}
        toggleTheme={toggleTheme}
        onUpgradeClick={() => setShowProModal(true)}
        onShowAuth={() => setShowAuthModal(true)}
        user={user}
        isPro={isPro}
      />

      <main className="container mx-auto px-4 py-8 max-w-[2000px]">
        {user ? (
          <UserDashboard 
            customColors={colors}
            onShowSettings={() => setShowSettings(true)}
            onShowAIInsights={() => setShowAIInsights(true)}
          />
        ) : (
          <div className="mb-8 text-center">
            <h2 className="text-2xl font-bold tracking-tight mb-2" style={{ color: colors.text }}>
              AI-Powered Investment Opportunities
            </h2>
            <p className="text-lg max-w-2xl mx-auto" style={{ color: colors.secondary }}>
              Discover profitable opportunities with AI-driven insights
            </p>
          </div>
        )}

        <div className="max-w-[2000px] mx-auto">
          <StatsOverview customColors={colors} />
          <AutomationProgress progress={automationProgress} customColors={colors} />
          {isPro && <DailyForecast chartData={chartData} customColors={colors} />}

          <Filters
            searchQuery={searchQuery}
            selectedTimeline={selectedTimeline}
            selectedCategory={selectedCategory}
            selectedRiskLevel={selectedRiskLevel}
            selectedProfitRange={selectedProfitRange}
            onSearchChange={setSearchQuery}
            onTimelineChange={(value: string) => setSelectedTimeline(value as "All Time" | "6 Months" | "1 Year" | "2+ Years")}
            onCategoryChange={(value: string) => setSelectedCategory(value as typeof categoryOptions[number])}
            onRiskLevelChange={(value: string) => setSelectedRiskLevel(value as typeof riskLevelOptions[number])}
            onProfitRangeChange={(value: string) => setSelectedProfitRange(value as typeof profitRangeOptions[number])}
            onResetFilters={handleResetFilters}
            customColors={colors}
          />

          <OpportunityGrid
            opportunities={filteredOpportunities}
            customColors={colors}
            isPremiumUser={isPro}
            trackedIds={trackedIds}
            onTrackToggle={toggleTrackedOpportunity}
          />
        </div>

        <ProModal
          open={showProModal}
          onOpenChange={setShowProModal}
          onUpgrade={handleUpgrade}
          customColors={colors}
        />

        <AuthModal
          open={showAuthModal}
          onOpenChange={setShowAuthModal}
          customColors={colors}
        />

        <UserSettings
          open={showSettings}
          onOpenChange={setShowSettings}
          customColors={colors}
        />

        <AIInsightsModal
          open={showAIInsights}
          onOpenChange={setShowAIInsights}
          insight={currentInsight}
          insights={insights}
          loading={insightLoading}
          onGenerateInsight={handleGenerateInsight}
          customColors={colors}
        />
      </main>
    </div>
  );
}