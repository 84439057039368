import { useMemo } from 'react';
import { Opportunity } from '@/types/profit';

interface UseProfitDataProps {
  opportunities: Opportunity[];
  searchQuery: string;
  selectedCategory: string;
  selectedRiskLevel: string;
  selectedProfitRange: string;
}

export function useProfitData({
  opportunities,
  searchQuery,
  selectedCategory,
  selectedRiskLevel,
  selectedProfitRange,
}: UseProfitDataProps) {
  return useMemo(() => {
    return opportunities.filter((opportunity) => {
      const matchesSearch = opportunity.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
                         opportunity.description.toLowerCase().includes(searchQuery.toLowerCase());
      const matchesCategory = selectedCategory === "All Categories" || 
                            opportunity.category === selectedCategory.toUpperCase();
      const matchesRiskLevel = selectedRiskLevel === "All Risks" || 
                              opportunity.riskLevel === selectedRiskLevel.toUpperCase();
      const matchesProfitRange = selectedProfitRange === "All Ranges" || 
                               (selectedProfitRange === "0-20%" && parseFloat(opportunity.roi) < 20) ||
                               (selectedProfitRange === "20-40%" && parseFloat(opportunity.roi) >= 20 && parseFloat(opportunity.roi) < 40) ||
                               (selectedProfitRange === "40%+" && parseFloat(opportunity.roi) >= 40);
      return matchesSearch && matchesCategory && matchesRiskLevel && matchesProfitRange;
    });
  }, [opportunities, searchQuery, selectedCategory, selectedRiskLevel, selectedProfitRange]);
}