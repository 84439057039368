export const themes = {
  light: {
    background: "#FFFFFF",
    cardBackground: "#F8F9FB",
    text: "#1A1D1E",
    primary: "#10B981", // Emerald-500
    secondary: "#64748B",
    accent: "#059669", // Emerald-600
    danger: "#EF4444",
    muted: "#E2E8F0",
    success: "#22C55E", // Green-500
    warning: "#F59E0B", // Amber-500
    info: "#3B82F6", // Blue-500
  },
  dark: {
    background: "#0F172A",
    cardBackground: "#1E293B",
    text: "#F8FAFC",
    primary: "#34D399", // Emerald-400
    secondary: "#94A3B8",
    accent: "#10B981", // Emerald-500
    danger: "#F87171",
    muted: "#334155",
    success: "#4ADE80", // Green-400
    warning: "#FCD34D", // Amber-300
    info: "#60A5FA", // Blue-400
  }
} as const;