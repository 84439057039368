import { createContext, useContext, useEffect, useState, ReactNode } from 'react';
import { supabase } from '@/lib/supabase';
import { Loader2 } from 'lucide-react';
import { profitData } from '@/data/profitData';
import { useToast } from '@/hooks/use-toast';
import { Json } from '@/types/supabase';

interface SupabaseContextType {
  isConnected: boolean;
}

const SupabaseContext = createContext<SupabaseContextType | undefined>(undefined);

export function SupabaseProvider({ children }: { children: ReactNode }) {
  const [isConnected, setIsConnected] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { toast } = useToast();

  useEffect(() => {
    let mounted = true;

    async function initialize() {
      try {
        // Test connection
        const { data: connectionTest, error: connectionError } = await supabase
          .from('opportunities')
          .select('count')
          .single();

        if (connectionError) {
          console.info('Database not yet initialized, proceeding with local data');
          if (mounted) {
            setIsConnected(false);
            setIsLoading(false);
          }
          return;
        }

        // Initialize categories
        const { data: categories } = await supabase
          .from('categories')
          .select('name');

        if (!categories?.length) {
          await supabase.from('categories').insert([
            { name: 'TECHNOLOGY', description: 'Technology and digital innovation investments' },
            { name: 'CLEANTECH', description: 'Clean technology and renewable energy solutions' },
            { name: 'AGRITECH', description: 'Agricultural technology and farming innovations' },
            { name: 'HEALTHTECH', description: 'Healthcare technology and digital health solutions' },
            { name: 'FINTECH', description: 'Financial technology and digital banking' },
            { name: 'EDTECH', description: 'Educational technology and e-learning platforms' },
            { name: 'BLOCKCHAIN', description: 'Blockchain and cryptocurrency investments' },
            { name: 'INFRASTRUCTURE', description: 'Infrastructure and smart city solutions' }
          ]);
        }

        // Initialize risk levels
        const { data: riskLevels } = await supabase
          .from('risk_levels')
          .select('name');

        if (!riskLevels?.length) {
          await supabase.from('risk_levels').insert([
            { name: 'LOW', description: 'Conservative investments with stable returns' },
            { name: 'MEDIUM', description: 'Balanced risk-reward opportunities' },
            { name: 'HIGH', description: 'High-risk, high-reward investments' }
          ]);
        }

        // Check if we need to seed opportunities
        const { count } = await supabase
          .from('opportunities')
          .select('*', { count: 'exact', head: true });

        if (count === 0) {
          // Get category and risk level IDs
          const { data: techCategory } = await supabase
            .from('categories')
            .select('id')
            .eq('name', 'TECHNOLOGY')
            .single();

          const { data: mediumRisk } = await supabase
            .from('risk_levels')
            .select('id')
            .eq('name', 'MEDIUM')
            .single();

          if (techCategory && mediumRisk) {
            // Insert sample opportunity
            const sampleOpportunity = {
              ...profitData[0],
              category_id: techCategory.id,
              risk_level_id: mediumRisk.id,
              initial_investment: profitData[0].profitMetrics.initialInvestment,
              projected_annual_return: profitData[0].profitMetrics.projectedAnnualReturn,
              breakeven_period: profitData[0].profitMetrics.breakevenPeriod,
              market_size: profitData[0].profitMetrics.marketSize,
              potential_profit: profitData[0].profitMetrics.potentialProfit,
              success_rate: profitData[0].profitMetrics.successRate,
              risk_factors: profitData[0].profitMetrics.riskFactors,
              market_analysis: "Detailed market analysis...",
              growth_potential: "High growth potential...",
              competitive_landscape: "Competitive analysis..."
            };

            const { error: insertError } = await supabase
              .from('opportunities')
              .insert([{
                ...sampleOpportunity,
                title: profitData[0].title,
                volume: profitData[0].volume,
                growth: profitData[0].growth,
                roi: profitData[0].roi,
                timeline: profitData[0].timeline,
                description: profitData[0].description,
                long_description: profitData[0].longDescription,
                is_premium: profitData[0].isPremium,
                icon: profitData[0].icon,
                trend: profitData[0].trend as unknown as Json[],
              }]);

            if (insertError) {
              console.error('Error seeding opportunities:', insertError);
            }
          }
        }

        if (mounted) {
          setIsConnected(true);
          toast({
            title: "Connected",
            description: "Successfully connected to the database",
          });
        }
      } catch (error) {
        console.error('Database initialization error:', error);
        if (mounted) {
          setIsConnected(false);
          toast({
            title: "Using Local Data",
            description: "Unable to connect to database. Using offline mode.",
            variant: "default",
          });
        }
      } finally {
        if (mounted) {
          setIsLoading(false);
        }
      }
    }

    initialize();

    return () => {
      mounted = false;
    };
  }, [toast]);

  if (isLoading) {
    return (
      <div className="fixed inset-0 flex items-center justify-center">
        <div className="flex flex-col items-center gap-4 p-4 rounded-lg text-center">
          <Loader2 className="h-6 w-6 animate-spin text-primary" />
            <span className="text-center">Initializing application...</span>
        </div>
      </div>
    );
  }

  return (
    <SupabaseContext.Provider value={{ isConnected }}>
      {children}
    </SupabaseContext.Provider>
  );
}

export function useSupabaseContext() {
  const context = useContext(SupabaseContext);
  if (context === undefined) {
    throw new Error('useSupabaseContext must be used within a SupabaseProvider');
  }
  return context;
}