import { memo } from 'react';
import { Calendar, TrendingUp, BookMarked } from 'lucide-react';
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Badge } from "@/components/ui/badge";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/tooltip";
import { EnhancedChart } from '../charts/EnhancedChart';
import { Opportunity, ThemeColors } from '@/types/profit';
import { getIconComponent } from '@/utils/iconUtils';
import { formatCurrency } from '@/utils/formatUtils';

interface OpportunityCardProps {
  opportunity: Opportunity;
  customColors: ThemeColors;
  isTracked: boolean;
  onTrackToggle: () => void;
  onViewDetails: () => void;
  isPremiumUser: boolean;
}

export const OpportunityCard = memo(function OpportunityCard({
  opportunity,
  customColors,
  isTracked,
  onTrackToggle,
  onViewDetails,
  isPremiumUser,
}: OpportunityCardProps) {
  const IconComponent = getIconComponent(opportunity.icon);
  
  const getRiskColor = (level: string) => {
    switch (level) {
      case "LOW":
        return customColors.success;
      case "MEDIUM":
        return customColors.warning;
      case "HIGH":
        return customColors.danger;
      default:
        return customColors.primary;
    }
  };

  return (
    <Card
      className="group relative overflow-hidden transition-all duration-300 hover:scale-[1.02] hover:shadow-xl"
      style={{
        backgroundColor: customColors.cardBackground,
        borderColor: 'transparent',
        boxShadow: `0 4px 6px -1px ${customColors.primary}10, 0 2px 4px -2px ${customColors.primary}10`
      }}
    >
      <CardHeader className="pb-2">
        <div className="flex items-start justify-between">
          <div className="flex items-center gap-3">
            <div 
              className="rounded-lg p-2.5"
              style={{ 
                background: `linear-gradient(135deg, ${customColors.primary}20, ${customColors.primary}40)`,
                boxShadow: `0 0 20px ${customColors.primary}10`
              }}
            >
              <IconComponent 
                className="h-5 w-5" 
                style={{ color: customColors.primary }} 
              />
            </div>
            <CardTitle 
              className="text-lg font-bold" 
              style={{ color: customColors.text }}
            >
              {opportunity.title}
            </CardTitle>
          </div>
          <Badge
            variant="outline"
            className="transition-colors"
            style={{
              backgroundColor: `${getRiskColor(opportunity.riskLevel)}20`,
              color: getRiskColor(opportunity.riskLevel),
              borderColor: 'transparent'
            }}
          >
            {opportunity.riskLevel}
          </Badge>
        </div>
        <div className="mt-3 flex items-center justify-between">
          <div className="flex items-center gap-2">
            <span className="text-sm" style={{ color: customColors.secondary }}>Volume:</span>
            <span className="font-mono text-sm font-medium" style={{ color: customColors.primary }}>
              {formatCurrency(parseFloat(opportunity.volume))}
            </span>
          </div>
          <div className="flex items-center gap-2">
            <span className="text-sm" style={{ color: customColors.secondary }}>ROI:</span>
            <span className="font-mono text-sm font-medium" style={{ color: customColors.accent }}>{opportunity.roi}</span>
          </div>
        </div>
      </CardHeader>
      <CardContent>
        <EnhancedChart 
          data={opportunity.trend} 
          dataKey="y" 
          color={customColors.primary} 
          customColors={customColors} 
        />
        <p 
          className="mb-4 text-sm line-clamp-2" 
          style={{ color: customColors.secondary }}
        >
          {opportunity.description}
        </p>
        <div className="flex items-center justify-between text-sm">
          <div className="flex items-center gap-2">
            <Calendar className="h-4 w-4" style={{ color: customColors.secondary }} />
            <span style={{ color: customColors.secondary }}>{opportunity.timeline}</span>
          </div>
          <div className="flex items-center gap-2">
            <TrendingUp className="h-4 w-4" style={{ color: customColors.success }} />
            <span style={{ color: customColors.success }}>{opportunity.growth}</span>
          </div>
        </div>
        <div className="mt-4 flex justify-between items-center">
          <Button 
            variant="outline"
            size="sm"
            onClick={onViewDetails}
            style={{ 
              borderColor: customColors.muted,
              color: customColors.text,
              backgroundColor: `${customColors.primary}10`
            }}
            className="hover:bg-opacity-20"
          >
            View Details
          </Button>
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <Button
                  variant="ghost"
                  size="sm"
                  onClick={onTrackToggle}
                  style={{ 
                    color: isTracked ? customColors.accent : customColors.secondary,
                    backgroundColor: isTracked ? `${customColors.accent}10` : 'transparent'
                  }}
                >
                  <BookMarked className="h-5 w-5" />
                </Button>
              </TooltipTrigger>
              <TooltipContent>
                <p>{isTracked ? 'Remove from tracked ideas' : 'Add to tracked ideas'}</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>
      </CardContent>
    </Card>
  );
});