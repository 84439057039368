import { memo } from 'react';
import { TrendingUp, LineChart, PieChart, Target } from 'lucide-react';
import { Card, CardContent, CardHeader, CardTitle, CardDescription } from "@/components/ui/card";
import { Progress } from "@/components/ui/progress";
import { ThemeColors } from '@/types/profit';

interface AutomationProgressProps {
  progress: number;
  customColors: ThemeColors;
}

export const AutomationProgress = memo(function AutomationProgress({
  progress,
  customColors
}: AutomationProgressProps) {
  return (
    <Card className="mb-6" style={{ backgroundColor: customColors.cardBackground }}>
      <CardHeader>
        <CardTitle className="flex items-center gap-2" style={{ color: customColors.text }}>
          <TrendingUp className="h-6 w-6" style={{ color: customColors.primary }} />
          Profit Strategy Guide
        </CardTitle>
        <CardDescription style={{ color: customColors.secondary }}>
          Key metrics and strategies to maximize your investment returns
        </CardDescription>
      </CardHeader>
      <CardContent>
        <div className="mb-4">
          <div className="flex justify-between mb-2">
            <span style={{ color: customColors.text }}>Portfolio Diversification</span>
            <span style={{ color: customColors.primary }}>{progress}%</span>
          </div>
          <Progress value={progress} className="w-full" />
        </div>
        <p className="text-sm mb-4" style={{ color: customColors.text }}>
          Maintain a balanced portfolio across different sectors to minimize risk and maximize potential returns.
        </p>
        <div className="grid grid-cols-2 gap-4">
          <div className="space-y-3">
            <div className="flex items-center gap-2">
              <LineChart className="h-5 w-5" style={{ color: customColors.accent }} />
              <div>
                <h4 className="font-medium" style={{ color: customColors.text }}>Market Analysis</h4>
                <p className="text-xs" style={{ color: customColors.secondary }}>Track market trends and economic indicators</p>
              </div>
            </div>
            <div className="flex items-center gap-2">
              <PieChart className="h-5 w-5" style={{ color: customColors.accent }} />
              <div>
                <h4 className="font-medium" style={{ color: customColors.text }}>Asset Allocation</h4>
                <p className="text-xs" style={{ color: customColors.secondary }}>Distribute investments across asset classes</p>
              </div>
            </div>
          </div>
          <div className="space-y-3">
            <div className="flex items-center gap-2">
              <Target className="h-5 w-5" style={{ color: customColors.accent }} />
              <div>
                <h4 className="font-medium" style={{ color: customColors.text }}>Risk Management</h4>
                <p className="text-xs" style={{ color: customColors.secondary }}>Set stop-losses and take-profit levels</p>
              </div>
            </div>
            <div className="flex items-center gap-2">
              <TrendingUp className="h-5 w-5" style={{ color: customColors.accent }} />
              <div>
                <h4 className="font-medium" style={{ color: customColors.text }}>Growth Strategy</h4>
                <p className="text-xs" style={{ color: customColors.secondary }}>Focus on high-potential opportunities</p>
              </div>
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  );
});