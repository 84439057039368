import { useEffect, useState } from 'react';
import { supabase } from '@/lib/supabase';
import { useAuth } from '@/providers/AuthProvider';

interface OpportunityStats {
  totalOpportunities: number;
  trackedCount: number;
  premiumCount: number;
  averageROI: string;
}

export function useOpportunityStats() {
  const { user } = useAuth();
  const [stats, setStats] = useState<OpportunityStats>({
    totalOpportunities: 0,
    trackedCount: 0,
    premiumCount: 0,
    averageROI: '0%'
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchStats() {
      try {
        // Get total and premium opportunities
        const { data: opportunities } = await supabase
          .from('opportunities')
          .select('roi, is_premium');

        if (opportunities) {
          const premiumCount = opportunities.filter(o => o.is_premium).length;
          const avgROI = opportunities
            .reduce((acc, curr) => acc + parseFloat(curr.roi), 0) / opportunities.length;

          // Get tracked count for user
          const { count: trackedCount } = await supabase
            .from('tracked_opportunities')
            .select('*', { count: 'exact' })
            .eq('user_id', user?.id || '');

          setStats({
            totalOpportunities: opportunities.length,
            trackedCount: trackedCount || 0,
            premiumCount,
            averageROI: `${avgROI.toFixed(1)}%`
          });
        }
      } catch (error) {
        console.error('Error fetching stats:', error);
      } finally {
        setLoading(false);
      }
    }

    fetchStats();
  }, [user?.id]);

  return { stats, loading };
}