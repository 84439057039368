import { ResponsiveContainer, AreaChart, Area, Tooltip } from 'recharts';
import { memo } from 'react';
import { ThemeColors } from '@/types/profit';
import { defaultChartMargin, defaultTooltipStyle } from './ChartDefaults';
import { XAxis, YAxis } from './ChartComponents';

interface EnhancedChartProps {
  data: Array<{ x: number; y: number }>;
  dataKey: string;
  color: string;
  customColors: ThemeColors;
  height?: number;
  hideAxis?: boolean;
}

export const EnhancedChart = memo(function EnhancedChart({ 
  data, 
  dataKey, 
  color, 
  customColors,
  height = 100,
  hideAxis = true
}: EnhancedChartProps) {
  const axisProps = {
    stroke: customColors.secondary,
    tick: { fill: customColors.secondary },
    tickLine: { stroke: customColors.secondary },
  };

  return (
    <div style={{ height: `${height}px` }}>
      <ResponsiveContainer width="100%" height={height}>
        <AreaChart data={data} margin={defaultChartMargin}>
          <defs>
            <linearGradient id={`gradient-${dataKey}`} x1="0" y1="0" x2="0" y2="1">
              <stop offset="0%" stopColor={color} stopOpacity={0.8} />
              <stop offset="100%" stopColor={color} stopOpacity={0} />
            </linearGradient>
          </defs>
          <Area
            type="monotone"
            dataKey={dataKey}
            stroke={color}
            fillOpacity={1}
            fill={`url(#gradient-${dataKey})`}
            isAnimationActive={false}
          />
          {!hideAxis && (
            <>
              <YAxis {...axisProps} />
              <XAxis {...axisProps} dataKey="x" />
            </>
          )}
          {hideAxis && (
            <>
              <YAxis {...axisProps} hide domain={['auto', 'auto']} />
              <XAxis {...axisProps} hide dataKey="x" />
            </>
          )}
          <Tooltip
            contentStyle={{
              ...defaultTooltipStyle,
              backgroundColor: customColors.cardBackground,
              borderColor: customColors.muted,
            }}
            labelStyle={{ color: customColors.text }}
            formatter={(value: number) => [`${value.toFixed(2)}`, dataKey.toUpperCase()]}
            labelFormatter={(label) => `Day ${label + 1}`}
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
});